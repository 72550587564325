import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CouponState,
  COUPON_STATE,
} from '../model/coupon-state.model';

export const selectState =
  createFeatureSelector<CouponState>(COUPON_STATE);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading
);

export const selectAccressToken = createSelector(
  selectState,
  (state) => state.accressToken
);

export const selectCoupons = createSelector(
  selectState,
  (state) => state.coupons
);

export const selectCouponDetail = createSelector(
  selectState,
  (state) => state.couponDetail
);

export const selectMyCoupons = createSelector(
  selectState,
  (state) => state.myCoupon
)

export const selectCouponList = createSelector(
  selectState,
  (state) => state.couponList
)

export const selectCollectCoupon = createSelector(
  selectState,
  (state) => state.collectCoupon
)

export const selectRedeemCoupon = createSelector(
  selectState,
  (state) => state.redeemCoupon
)

export const selectReserveCouponOrder = createSelector(
  selectState,
  (state) => state.reserveCouponOrder
)

export const selectCustomerTutorial = createSelector(
  selectState,
  (state) => state.customerTutorial
)

export const selectPermissionUseCoupon = createSelector(
  selectState,
  (state) => state.permissionUseCoupon
);

export const selectCouponCategory = createSelector(
  selectState,
  (state) => state.categories
);

export const selectSearchKeyword = createSelector(
  selectState,
  (state) => state.couponSearch.keyword
);

export const selectDefaultSearchKeyword = createSelector(
  selectState,
  (state) => state.couponSearch.defaultSearchKeyword
);

export const selectDefaultSearchKeywordIndex = createSelector(
  selectState,
  (state) => state.couponSearch.defaultSearchKeywordIndex
);

export const selectCouponSearchResult = createSelector(
  selectState,
  (state) => state.couponSearch.searchResult
);