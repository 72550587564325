export enum RegisterStatus {
    PENDING = 'PENDING',
    REGISTERING = 'REGISTERING',
    ACTIVE = 'ACTIVE',
    UNREGISTERED = 'UNREGISTERED'
}

export enum CouponStatus {
    UNCOLLECTED = 'Uncollected',
    COLLECTED = 'Collected',
    NEW = 'New',
    REDEEMED = 'Redeemed',
    REDEEM_CONFIRMED = 'Redeem_Confirmed',
    EXPIRED = 'Expired',
    OUT_OF_QUOTA = 'Out_Of_Quota',
    OUT_OF_USED_QUOTA = 'Out_Of_Used_Quota', // Both collect and redeem, separeate by order that 0:collect
    TEMPO_OUT_OF_QUOTA = 'Temporary_Out_Of_Quota',
    NOT_ELIGIBLE_TO_USE = 'Not_Eligible_To_Use',
}

export enum CouponChannel {
    CNX = 'CNX',
    PT = 'PT',
    NEXT = 'NEXT',
    MBP = 'MBP',
    PW = 'PW'
}

export enum CouponMethod {
    COPAY = 'COPAY'
}

export enum BadgeLabel {
    HOT = 'Hot',
    NEW = 'New',
    DISABLE = 'Disable'
}

export enum PeriodStatus {
    ALLOWED = 'Allowed',
    PERIOD = 'Period',
    DATE = 'Date'
}

export enum DeductionType {
    COLLECTED = 'collected',
    REDEEMED = 'redeemed'
}

export enum MyCouponTab {
    ACTIVE_COUPON = '1',
    USED_COUPON = '2',
    EXPIRED_COUPON = '3',
}

export enum UseActionTypeModalTemplate {
    SUCCESS = 'Success',
    EXPIRED = 'Expired',
    REDEEMED = 'Redeemed',
    PERIOD = 'Period'
}

export enum CopayNavigationType {
    INTERNAL_PT = 'internalPT',
    EXTERNAL_URL = 'externalURL',
    EXTERNAL_DEEPLINK = 'externalDeeplink'
}

export enum InternalNavigationType {
    PT_PAYMENT = 'PT_PAYMENT',
    PT_TOPUP = 'PT_TOPUP'
}

export enum Destination {
    MISSION_BASE = 'MISSION_BASE'
}

