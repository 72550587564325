import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { Store } from '@ngrx/store';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { CouponService } from 'src/shared/coupon.service';
import { isNil } from 'lodash';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponEventTagPageView } from 'src/app/model/coupon-ga.model';
import { CouponChannel } from 'src/app/model/coupon-enum.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { FirebaseScreen } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { AnalyticCategory } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { Subject, takeUntil } from 'rxjs';


declare let ga: Function;
declare let window: any;

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss']
})

export class UnderMaintenanceComponent implements OnInit {
  couponChannel = this.rootStoreService.jwtSession?.couponChannel;
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  CouponChannelEnum = CouponChannel;
  private _destroy = new Subject<void>();

  constructor(
    private utilService: UtilService,
    private rootStoreService: RootStoreService,
    private couponService: CouponService,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) {
  }

  ngOnInit(): void {

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.couponChannel = jwtSession.couponChannel;
    })

    this.utilService.stopLoading();

    const gaPageObj: CouponEventTagPageView = {
      pageTitle: AnalyticCategory.COUPON_UNDER_MAINTENANCE,
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackPageViewGATag(gaPageObj);

    const eventPageView: FirebaseScreen = {
      firebase_screen: AnalyticCategory.COUPON_UNDER_MAINTENANCE,
    }
    this.webviewToNativeInterfaceService.trackPageView(eventPageView);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
