<div id="unauthorized-content" class="bg-special">
    <div [class]="isAndroid ? ' android-device' : ''">        
        <div class="bg-blue">
            <div class="header has-text-centered">
                <h3>&nbsp;</h3>
                <div class="has-text-left back-btn" *ngIf="withHeader">
                    <a [href]="callbackUrl | safe: 'url'" data-testid="link-back-icon">

                    </a>
                </div>
            </div>
            <div class="container margin-top">        
                <div class="has-text-centered">
                    <img src="../../../assets/images/bg-unauthorized.png">
                    <p>
                        ไม่สามารถเข้าใช้งานผ่านช่องทางนี้ได้<br>
                        กรุณาเข้าผ่าน แอปฯ เป๋าตัง, Krungthai NEXT <br>หรือ Line Connext<br>(1004)
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
