import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { CouponCountdownModel } from '../model/coupon-modal.model';
import { CouponStatus, PeriodStatus } from '../model/coupon-enum.model';

declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    handleCouponCountdown = new Subject<CouponCountdownModel>();
    couponData = new Subject<any>();
    constructor(
        private dialog: MatDialog,
    ) { }

    openModal<T, D = any>(component: ComponentType<T>, config?: MatDialogConfig<D> | undefined) {
        let marginBottom = '100%';

        const modal = this.dialog.open(component, { data: config?.data, disableClose: true, maxWidth: '100vw', width: '93vw', autoFocus: false, position: { bottom: marginBottom } });

        return modal.afterClosed();
    }

    openDialogButtomContnet<T, D = any>(component: ComponentType<T>, config?: MatDialogConfig<D> | undefined) {
        const modal = this.dialog.open(component, {
            id: 'bottom-dialog-content',
            data: config?.data, disableClose: true,
            maxHeight: '99vh', maxWidth: '100vw',
            width: '100vw', autoFocus: false,
            position: config?.position,
            panelClass: ['dialog-slide-animate', 'dialog-side-panel'],
            restoreFocus: true
        });

        modal.backdropClick().subscribe(() => {
            document.getElementsByClassName("dialog-slide-animate")[0]?.classList.remove("dialog-side-panel")
            document.getElementsByClassName("dialog-slide-animate")[0]?.classList.add("dialog-slide-out-panel");
            setTimeout(() => {
                this.dialog.closeAll()
            }, 200);
        });

        return modal.afterClosed();
    }

    getHeaderDialogButtomContnet(periodStatus: PeriodStatus, couponStatus: CouponStatus, isInChannel: boolean = true, isAboveMinimumSpend: boolean = true) {
        if (!isInChannel) {
            return "คูปองที่ใช้ไม่ได้";
        }

        let titleDialog = '';
        if (periodStatus === PeriodStatus.ALLOWED) {
            if (couponStatus === CouponStatus.NEW || couponStatus === CouponStatus.COLLECTED || couponStatus === CouponStatus.REDEEMED)
                titleDialog = "คูปองที่พร้อมใช้";

            if (couponStatus === CouponStatus.REDEEM_CONFIRMED)
                titleDialog = "คูปองที่ใช้แล้ว";

            if (couponStatus === CouponStatus.OUT_OF_QUOTA || couponStatus === CouponStatus.EXPIRED || couponStatus === CouponStatus.OUT_OF_USED_QUOTA || couponStatus === CouponStatus.TEMPO_OUT_OF_QUOTA || (couponStatus === CouponStatus.COLLECTED && !isAboveMinimumSpend))
                titleDialog = "คูปองที่ใช้ไม่ได้";

            if (couponStatus === CouponStatus.NOT_ELIGIBLE_TO_USE)
                titleDialog = "คูปองที่ไม่ตรงเงื่อนไข";
        } else {
            if (couponStatus === CouponStatus.OUT_OF_QUOTA || couponStatus === CouponStatus.EXPIRED || couponStatus === CouponStatus.OUT_OF_USED_QUOTA || couponStatus === CouponStatus.TEMPO_OUT_OF_QUOTA)
                titleDialog = "คูปองที่ใช้ไม่ได้";
            else if (couponStatus === CouponStatus.REDEEM_CONFIRMED)
                titleDialog = "คูปองที่ใช้แล้ว";
            else if (couponStatus === CouponStatus.NOT_ELIGIBLE_TO_USE)
                titleDialog = "คูปองที่ไม่ตรงเงื่อนไข";
            else
                titleDialog = "คูปองที่รอใช้";
        }
        return titleDialog;
    }

    closeAllDialog() {
        this.dialog.closeAll();
    }

}
