<section class="modal-card-body modal-card-confirm">
    <div class="content-custom">
        <ng-container *ngIf="isFirstCoachMark else secondCoachMark">
            <div class="content-custom-top">
                <h2 data-test="coach-first-title" class="font-bold">กดเก็บคูปองที่นี่เลย</h2>
                <p>เก็บคูปองง่ายๆ ได้ส่วนลดแบบคุ้มๆ!</p>
            </div>
            <div class="content-custom-bottom">
                <div class="nav-container">
                    <span class="nav"><span class="circle active"></span></span>
                    <span class="nav"><span class="circle"></span></span>
                </div>
                <button data-test="btn-coach-first" class="btn-next font-bold" (click)="onActionNext.emit()">ถัดไป <img src="../../../assets/icon/ic_chevron-right.png" width="16" /></button>
            </div>
        </ng-container>

        <ng-template #secondCoachMark>
            <div class="content-custom-top">
                <h2 data-test="coach-start-title" class="font-bold"> ดูคูปองทั้งหมดของคุณ</h2>
                <p>ทุกคูปองที่คุณเก็บไว้ สามารถดูได้ที่นี่!</p>
            </div>
            <div class="content-custom-bottom">
                <div class="nav-container">
                    <span class="nav"><span class="circle"></span></span>
                    <span class="nav"><span class="circle active"></span></span>
                </div>
                <button class="btn-previous" (click)="onActionBack.emit()"><img src="../../../assets/icon/ic_chevron-right.png" width="16" /></button>
                <button class="btn-start font-bold" data-test="btn-coach-start" (click)="onActionStart.emit()">เริ่มใช้เลย!</button>
            </div>
        </ng-template>
    </div>
</section>