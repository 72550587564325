import { cloneDeep } from "lodash";
import { CouponStatus, PeriodStatus } from "../model/coupon-enum.model";
import { ICollectionCoupon, ICouponInfo, ICouponListModel, ICouponModel, ICouponResponse, IFetchCouponData, IFetchCouponList, IFetchMyCouponData, IMyCouponModel, IOrderCouponList } from "../model/coupon-model.model";

export function transformIFetchCouponHomeData(response: ICouponResponse<IFetchCouponData>) {
    const result: ICouponResponse<IFetchCouponData> = {
        data: {
            collections: transformICollectionCoupon(response.data.collections),
            accessToken: response.data.accessToken,
        },
        result: response.result
    }

    return result;
}

export function transformICollectionCoupon(response: ICollectionCoupon[]): ICollectionCoupon[] {

    return response?.map((collection: ICollectionCoupon) => {

        const categories = collection.categories && collection.categories.length > 0 ? [
            {
                categoryUUID: "0",
                priority: 0,
                name: "ทั้งหมด"
            },
            ...collection.categories
        ] : [];

        const transformCollection: ICollectionCoupon = {
            collectionId: collection.collectionId,
            coupons: separateUsedCouponsHomePageAsCouponStatus(collection.coupons ?? []),
            priority: collection.priority,
            title: collection.title,
            numberOfCollectedCoupon: collection.numberOfCollectedCoupon,
            categories: categories,
            defaultSearchWord: collection.defaultSearchWord ?? []
        };

        return transformCollection
    }) ?? [];
}

export function transformIFetchMyCouponData(response: ICouponResponse<IFetchMyCouponData>) {
    const result: ICouponResponse<IFetchMyCouponData> = {
        data: {
            accessToken: response.data.accessToken,
            activeCoupons: response.data.activeCoupons,
            expiredCoupons: response.data.expiredCoupons,
            usedCoupons: separateUsedCouponsAsCouponStatus(response.data.usedCoupons ?? [], [CouponStatus.REDEEMED, CouponStatus.REDEEM_CONFIRMED]),
        },
        result: response.result
    }

    return result;
}

export function transformIFetchCouponList(response: ICouponResponse<IFetchCouponList>) {
    const result: ICouponResponse<IFetchCouponList> = {
        data: {
            accessToken: response.data.accessToken,
            eligibleCoupons: response.data.eligibleCoupons ?? [], // separateCouponListAsCouponStatus(response.data.eligibleCoupons ?? []),
            paymentDeeplinkUrl: response.data.paymentDeeplinkUrl,
        },
        result: response.result
    }
    return result;
}


export function separateUsedCouponsHomePageAsCouponStatus(coupons: ICouponModel[]) {
    const result: ICouponModel[] = [];

    const filterCoupons = new Map<string, ICouponModel[]>();
    if (!filterCoupons.has(CouponStatus.TEMPO_OUT_OF_QUOTA)) filterCoupons.set(CouponStatus.TEMPO_OUT_OF_QUOTA, []);
    if (!filterCoupons.has(CouponStatus.NOT_ELIGIBLE_TO_USE)) filterCoupons.set(CouponStatus.NOT_ELIGIBLE_TO_USE, []);
    if (!filterCoupons.has(CouponStatus.OUT_OF_QUOTA)) filterCoupons.set(CouponStatus.OUT_OF_QUOTA, []);
    if (!filterCoupons.has(CouponStatus.OUT_OF_USED_QUOTA)) filterCoupons.set(CouponStatus.OUT_OF_USED_QUOTA, []);
    if (!filterCoupons.has(CouponStatus.REDEEM_CONFIRMED)) filterCoupons.set(CouponStatus.REDEEM_CONFIRMED, []);

    coupons.forEach((coupon: ICouponModel) => {
        const inCludeStatus = coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.COLLECTED || couponInfo.status === CouponStatus.REDEEMED || couponInfo.status === CouponStatus.REDEEM_CONFIRMED);
        if (!inCludeStatus) {

            if (coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
                filterCoupons.get(CouponStatus.TEMPO_OUT_OF_QUOTA)!.push(coupon);
                return;
            }

            if (coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.NOT_ELIGIBLE_TO_USE)) {
                filterCoupons.get(CouponStatus.NOT_ELIGIBLE_TO_USE)!.push(coupon);
                return;
            }

            if (coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.OUT_OF_QUOTA)) {
                filterCoupons.get(CouponStatus.OUT_OF_QUOTA)!.push(coupon);
                return;
            }

            if (coupon.couponInfo.some((couponInfo) => couponInfo.status === CouponStatus.OUT_OF_USED_QUOTA)) {
                filterCoupons.get(CouponStatus.OUT_OF_USED_QUOTA)!.push(coupon);
                return;
            }

            result.push(coupon);
            return;
        }

        const filterCouponStatus = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.COLLECTED || couponInfo.status === CouponStatus.REDEEMED);
        const filterCouponStatusNew = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.NEW);
        const isCouponStatusNewPeriodAllow = filterCouponStatusNew.some((couponInfo) => couponInfo.periodStatus === PeriodStatus.ALLOWED);

        if (filterCouponStatus.length > 0 && (filterCouponStatusNew.length === 0 || !isCouponStatusNewPeriodAllow)) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatus);
            result.push(couponModel);
        }

        if (filterCouponStatusNew.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatusNew);
            result.push(couponModel);
        }

        const filterCouponStatusTempOutOfQuota = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.TEMPO_OUT_OF_QUOTA);
        if (filterCouponStatusTempOutOfQuota.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatusTempOutOfQuota);
            filterCoupons.get(CouponStatus.TEMPO_OUT_OF_QUOTA)!.push(couponModel);
        }

        const filterCouponStatusNotEligibleToUse = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.NOT_ELIGIBLE_TO_USE);
        if (filterCouponStatusNotEligibleToUse.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatusNotEligibleToUse);
            filterCoupons.get(CouponStatus.NOT_ELIGIBLE_TO_USE)!.push(couponModel);
        }

        const filterCouponStatusOutOfQuota = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.OUT_OF_QUOTA);
        if (filterCouponStatusOutOfQuota.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatusOutOfQuota);
            filterCoupons.get(CouponStatus.OUT_OF_QUOTA)!.push(couponModel);
        }

        const filterCouponStatusOutOfUsedQuota = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.OUT_OF_USED_QUOTA);
        if (filterCouponStatusOutOfUsedQuota.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponStatusOutOfUsedQuota);
            filterCoupons.get(CouponStatus.OUT_OF_USED_QUOTA)!.push(couponModel);
        }

        const filterCouponRedeemConfirm = coupon.couponInfo.filter((couponInfo) => couponInfo.status === CouponStatus.REDEEM_CONFIRMED);
        if (filterCouponRedeemConfirm.length > 0) {
            const couponModel: ICouponModel = mappingICouponModel(coupon, filterCouponRedeemConfirm);
            filterCoupons.get(CouponStatus.REDEEM_CONFIRMED)!.push(couponModel);
        }
    });

    filterCoupons.forEach((value: ICouponModel[], key: string) => {

        if (key === CouponStatus.REDEEM_CONFIRMED) {
            value.sort(function (a, b) {
                return new Date(b.couponInfo[0].redeemedDate!).getTime() - new Date(a.couponInfo[0].redeemedDate!).getTime();
            });
            result.push(...value);
            return;
        }

        result.push(...value);
        return;

    });

    return result;
}

function mappingICouponModel(coupon: ICouponModel, couponInfo: ICouponInfo[]): ICouponModel {
    return {
        couponId: coupon.couponId,
        campaignCode: coupon.campaignCode,
        couponName: coupon.couponName,
        couponImage: coupon.couponImage,
        couponShortTitle: coupon.couponShortTitle,
        couponSubtitle: coupon.couponSubtitle,
        badgeLabel: coupon.badgeLabel,
        couponType: coupon.couponType,
        hasCountdownTimer: coupon.hasCountdownTimer,
        isHiddenProgressBar: coupon.isHiddenProgressBar,
        couponUseMethod: coupon.couponUseMethod,
        copayChannels: coupon.copayChannels,
        numberOfCoupons: couponInfo.length,
        couponRedemptionWithIn: coupon.couponRedemptionWithIn,
        deductionType: coupon.deductionType,
        progressionPercent: coupon.progressionPercent,
        maximumCouponPerUser: coupon.maximumCouponPerUser,
        repetitiveStatus: coupon.repetitiveStatus,
        maximumRepetitiveCouponPerUser: coupon.maximumRepetitiveCouponPerUser,
        isRepetitiveResetQuota: coupon.isRepetitiveResetQuota,
        numberOfOutOfQuotaCoupon: coupon.numberOfOutOfQuotaCoupon,
        numberOfTemporaryOutOfQuotaCoupon: coupon.numberOfTemporaryOutOfQuotaCoupon,
        repetitiveTime: coupon.repetitiveTime,
        isUseButtonDisplay: coupon.isUseButtonDisplay,
        sourceOfFunds: coupon.sourceOfFunds,
        categoryUUIDs: coupon.categoryUUIDs ?? [],
        tags: coupon.tags ?? [],
        copayNavigation: coupon.copayNavigation,
        isCoPayNavigate: coupon.isCoPayNavigate,
        couponInfo: couponInfo
    };
}
export function separateUsedCouponsAsCouponStatus(coupons: IMyCouponModel[], filterStatus: CouponStatus[]) {
    const filterCoupons = new Map<string, IMyCouponModel[]>();
    coupons.forEach((coupon: IMyCouponModel) => {
        filterStatus.forEach((status: CouponStatus) => {
            const filterCouponStatus = coupon.orders.filter((order) => order.status === status);

            if (!filterCoupons.has(status)) filterCoupons.set(status, []);

            if (filterCouponStatus.length > 0) {
                const usedCoupon: IMyCouponModel = {
                    couponId: coupon.couponId,
                    campaignCode: coupon.campaignCode,
                    couponName: coupon.couponName,
                    couponShortTitle: coupon.couponShortTitle,
                    couponSubtitle: coupon.couponSubtitle,
                    couponImage: coupon.couponImage,
                    badgeLabel: coupon.badgeLabel,
                    couponUseMethod: coupon.couponUseMethod,
                    copayChannels: coupon.copayChannels,
                    isHiddenProgressBar: coupon.isHiddenProgressBar,
                    deductionType: coupon.deductionType,
                    progressionPercent: coupon.progressionPercent,
                    isUseButtonDisplay: coupon.isUseButtonDisplay,
                    copayNavigation: coupon.copayNavigation,
                    isCoPayNavigate: coupon.isCoPayNavigate,
                    numberOfCoupons: filterCouponStatus.length,
                    orders: filterCouponStatus
                };
                filterCoupons.get(status)!.push(usedCoupon);
            }
        });
    });

    const result: IMyCouponModel[] = [];
    filterCoupons.forEach((value: IMyCouponModel[]) => {
        result.push(...value);
    });

    return result;
}

export function mappingCollectedCouponList(existingCouponList: ICouponListModel[], newCouponList: ICouponListModel[]) {

    const newCouponOrder = cloneDeep(newCouponList);
    const newCollectCoupon = newCouponOrder.find(newCoupon => newCoupon.orders[0].status === CouponStatus.COLLECTED)!;
    // Use to push on last coupon.
    const newOutOfQuotaCoupons = newCouponOrder.find(newCoupon => newCoupon.orders[0].status !== CouponStatus.COLLECTED);
    const stateCoupon = existingCouponList;
    const existingCoupon = stateCoupon.find((coupon) => coupon.couponId === newCollectCoupon.couponId && coupon.orders[0].status === CouponStatus.COLLECTED);
    const collectedCoupon = stateCoupon.find((coupon) => coupon.couponId === newCollectCoupon.couponId && coupon.orders[0].status === CouponStatus.NEW);

    if (existingCoupon) {
        const existingCouponOrder = existingCoupon.orders.map((order) => order.order);
        newCollectCoupon.orders = newCollectCoupon.orders.filter((order) => !existingCouponOrder.includes(order.order));
    }

    if (collectedCoupon) {
        collectedCoupon.couponId = newCollectCoupon.couponId;
        collectedCoupon.campaignCode = newCollectCoupon.campaignCode;
        collectedCoupon.couponShortTitle = newCollectCoupon.couponShortTitle;
        collectedCoupon.couponSubtitle = newCollectCoupon.couponSubtitle;
        collectedCoupon.deductionType = newCollectCoupon.deductionType;
        collectedCoupon.imageUrl = newCollectCoupon.imageUrl;
        collectedCoupon.isHiddenProgressBar = newCollectCoupon.isHiddenProgressBar;
        collectedCoupon.maximumCouponPerUser = newCollectCoupon.maximumCouponPerUser;
        collectedCoupon.maximumRepetitiveCouponPerUser = newCollectCoupon.maximumRepetitiveCouponPerUser;
        collectedCoupon.numberOfTemporaryOutOfQuotaCoupon = newCollectCoupon.numberOfTemporaryOutOfQuotaCoupon;
        collectedCoupon.numberOfOutOfQuotaCoupon = newCollectCoupon.numberOfOutOfQuotaCoupon;
        collectedCoupon.name = newCollectCoupon.name;
        collectedCoupon.numberOfCollectedCoupons = newCollectCoupon.orders.length;
        collectedCoupon.progressionPercent = newCollectCoupon.progressionPercent;
        collectedCoupon.repetitiveStatus = newCollectCoupon.repetitiveStatus;
        collectedCoupon.isRepetitiveResetQuota = newCollectCoupon.isRepetitiveResetQuota;
        collectedCoupon.repetitiveTime = newCollectCoupon.repetitiveTime;
        collectedCoupon.orders = newCollectCoupon.orders;
    }

    if (newOutOfQuotaCoupons) {
        stateCoupon.push(newOutOfQuotaCoupons);
    }

    return stateCoupon;
}

export function mappingCollectedCouponHome(existingCouponList: ICouponModel[], newCouponList: ICouponModel[], inActiveCoupon: ICouponModel[]) {

    const newCouponOrder = cloneDeep(newCouponList);
    const newCollectCoupon = newCouponOrder.find(newCoupon => newCoupon.couponInfo[0].status === CouponStatus.COLLECTED || newCoupon.couponInfo[0].status === CouponStatus.REDEEMED)!;
    let stateCoupon = existingCouponList;
    const collectedCoupon: ICouponModel = stateCoupon.find((coupon) => coupon.couponId === newCollectCoupon.couponId && coupon.couponInfo[0].status === CouponStatus.NEW)!;

    if (collectedCoupon) {
        collectedCoupon.couponId = newCollectCoupon.couponId;
        collectedCoupon.campaignCode = newCollectCoupon.campaignCode;
        collectedCoupon.couponName = newCollectCoupon.couponName;
        collectedCoupon.couponImage = newCollectCoupon.couponImage;
        collectedCoupon.couponType = newCollectCoupon.couponType;
        collectedCoupon.couponInfo = newCollectCoupon.couponInfo;
        collectedCoupon.couponRedemptionWithIn = newCollectCoupon.couponRedemptionWithIn;
        collectedCoupon.isHiddenProgressBar = newCollectCoupon.isHiddenProgressBar;
        collectedCoupon.badgeLabel = newCollectCoupon.badgeLabel;
        collectedCoupon.couponShortTitle = newCollectCoupon.couponShortTitle;
        collectedCoupon.couponSubtitle = newCollectCoupon.couponSubtitle;
        collectedCoupon.couponUseMethod = newCollectCoupon.couponUseMethod;
        collectedCoupon.copayChannels = newCollectCoupon.copayChannels;
        collectedCoupon.hasCountdownTimer = newCollectCoupon.hasCountdownTimer;
        collectedCoupon.numberOfCoupons = newCollectCoupon.couponInfo.length;
        collectedCoupon.deductionType = newCollectCoupon.deductionType;
        collectedCoupon.progressionPercent = newCollectCoupon.progressionPercent;
        collectedCoupon.maximumCouponPerUser = newCollectCoupon.maximumCouponPerUser;
        collectedCoupon.numberOfOutOfQuotaCoupon = newCollectCoupon.numberOfOutOfQuotaCoupon;
        collectedCoupon.numberOfTemporaryOutOfQuotaCoupon = newCollectCoupon.numberOfTemporaryOutOfQuotaCoupon;
        collectedCoupon.repetitiveStatus = newCollectCoupon.repetitiveStatus;
        collectedCoupon.maximumRepetitiveCouponPerUser = newCollectCoupon.maximumRepetitiveCouponPerUser;
        collectedCoupon.isRepetitiveResetQuota = newCollectCoupon.isRepetitiveResetQuota;
        collectedCoupon.repetitiveTime = newCollectCoupon.repetitiveTime;
    }

    stateCoupon = stateCoupon.filter((coupon: ICouponModel) => (coupon.couponInfo[0].status === CouponStatus.NEW || coupon.couponInfo[0].status === CouponStatus.COLLECTED || coupon.couponInfo[0].status === CouponStatus.REDEEMED))
    stateCoupon.push(...inActiveCoupon);

    return stateCoupon;
}