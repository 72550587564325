<ng-container [ngSwitch]="couponTemplate">
    <ng-container *ngSwitchCase="CouponCardTemplate.COUPON_HOME">
        <ng-container *ngTemplateOutlet="couponHomeTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="CouponCardTemplate.MY_COUPON">
        <ng-container *ngTemplateOutlet="myCouponTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="CouponCardTemplate.COUPON_LIST">
        <ng-container *ngTemplateOutlet="couponListTemplate"></ng-container>
    </ng-container>
</ng-container>

<ng-template #couponHomeTemplate>
    <table class="wrap-content" [attr.data-testid]="'coupon-bottom-dialog'">
        <tr *ngFor="let coupon of coupons; trackBy: identify let i = index"
            [id]="coupon.couponId + '_' + getPrefixCouponStatus(coupon.couponInfo)" #couponCard
            [attr.data-testid]="'coupon-list-card'" class="scroll-margin-top">

            <td class="coupon-column wrap-coupon" [attr.data-testid]="'link-image-coupon-detail-' + i"
                [ngClass]="{
                    'padding-multiple': isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon,coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1 }"
                (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateFormHomepageToCouponDetail(coupon)">

                <!-- Box shadow check multiple coupon :D -->
                <img *ngIf="isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1"
                    class="mask-left-shadow" [src]="coupon.couponImage" loading="lazy"
                    [attr.data-testid]="'link-coupon-list-detail' + i">

                <img class="mask-left" [src]="coupon.couponImage" loading="lazy"
                    [attr.data-testid]="'link-coupon-list-detail' + i">

                <!-- Stamp coupon need to comfirm status with BE -->
                <div [ngClass]="{'stamp-used': coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED, 
                        'stamp-expired':  coupon.couponInfo[0].status === CouponStatus.EXPIRED, 
                        'stampsold-out': coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA || coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA,
                        'stamp-tempo-sold-out': coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA,
                        'stamp-unconditional': coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE }">
                </div>

                <!-- Badge Level parameter with BE -->
                <ng-container *ngIf="isDisplayBadgeLabel(coupon.couponInfo[0].status)">
                    <div class="badge badge-green" *ngIf="coupon.badgeLabel === BadgeLabel.NEW">New</div>
                    <div class="badge badge-red" *ngIf="coupon.badgeLabel === BadgeLabel.HOT">Hot</div>
                </ng-container>
            </td>

            <td class="wrap-coupon">
                <div class="shadow-content">
                    <!-- Box shadow check multiple coupon :D -->
                    <div *ngIf="isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1"
                        class="box-content mask-right-shadow"></div>

                    <div class="box-content mask-right">
                        <div class="padding-content box-top-height" [attr.data-testid]="'link-title-coupon-detail-' + i"
                            (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateFormHomepageToCouponDetail(coupon)">
                            <!-- -->
                            <h4 class="newline-wrap coupon-title" [id]="'title-'+i"
                                [ngClass]="{'title-multiple': isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1 }"
                                style="--gap: {{badgeNumber.offsetWidth}}px">{{coupon.couponShortTitle}}
                            </h4>
                            <span class="newline-wrap coupon-description"
                                [id]="'description-'+i">{{coupon.couponSubtitle}}</span>

                            <!-- Check logic progress bar >= 80 && <= 99 and check parameter with BE -->
                            <div class="section-progress"
                                *ngIf="isDisplayProgressBarLabel(coupon.couponInfo[0].status) && !coupon.isHiddenProgressBar && coupon.progressionPercent >= 80 && coupon.progressionPercent <= 99">
                                <div
                                    *ngIf="(coupon.deductionType === DeductionType.COLLECTED && coupon.couponInfo[0].status === CouponStatus.NEW) || coupon.deductionType === DeductionType.REDEEMED">
                                    <div class="is-inline">
                                        <progress class="progress orange" [value]="coupon.progressionPercent"
                                            max="100"></progress>
                                        <div class="is-inline-block v-align-top">
                                            <div class="pl-2">
                                                <span class="text-progress">{{coupon.couponInfo[0].status ===
                                                    CouponStatus.COLLECTED ? (
                                                    "ใช้แล้ว"
                                                    ) : (
                                                    coupon.deductionType === "collected" ?
                                                    ( "เก็บแล้ว" ) : ( "ใช้แล้ว" )
                                                    )}}</span>
                                                <span class="text-progress-num orange">
                                                    {{coupon.progressionPercent}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-bottom">
                            <table class="wrap-content"
                                [class.table-fixed]="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                <tr>
                                    <td class="v-align-bottom" width="80%"
                                        [class.coupon-info-ellipsis-text]="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE"
                                        [attr.data-testid]="'link-card-coupon-detail-' + i"
                                        (click)="coupon.couponInfo[0].status !== CouponStatus.NEW && coupon.couponInfo[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.couponInfo[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateFormHomepageToCouponDetail(coupon)">
                                        <!-- Check status display bottom text with parameter from BE  -->
                                        <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.REDEEM_CONFIRMED">
                                            <!-- Need to check parameter of used date -->
                                            วันที่ใช้ {{convertDateFormat(coupon.couponInfo[0].redeemedDate)}}
                                        </span>

                                        <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.OUT_OF_QUOTA">
                                            ถูกเก็บหมดแล้ว
                                        </span>

                                        <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i"
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.OUT_OF_USED_QUOTA">
                                            ถูกใช้หมดแล้ว
                                        </span>

                                        <span class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-date-' + i"
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                            กดเพื่อดูเงื่อนไขการเก็บคูปองในครั้งถัดไป
                                        </span>

                                        <span
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.NEW && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                            class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-date-' + i">
                                            เริ่มเก็บได้ {{ coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD ?
                                            (convertTimeFormat(coupon.couponInfo[0].couponStartDatetime) + '-' +
                                            convertTimeFormat(coupon.couponInfo[0].couponEndDatetime))
                                            : convertDateFormat(coupon.couponInfo[0].couponStartDatetime) }}
                                        </span>

                                        <span
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.COLLECTED && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                            class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-date-' + i">
                                            {{ isInChannel(coupon) ? 'เริ่มใช้ได้ ' + (coupon.couponInfo[0].periodStatus
                                            ===
                                            PeriodStatus.PERIOD ?
                                            (convertTimeFormat(coupon.couponInfo[0].couponStartRedemptionDatetime) + '-'
                                            +
                                            convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime))
                                            : convertDateFormat(coupon.couponInfo[0].couponStartRedemptionDatetime)) :
                                            'ไม่สามารถใช้กับช่องทางนี้ได้' }}
                                        </span>

                                        <span class="font-light expire-text"
                                            *ngIf="(coupon.couponInfo[0].status === CouponStatus.NEW) && coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED"
                                            [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                            หมดอายุ {{ convertDateFormat(coupon.couponInfo[0].couponEndDatetime) }}
                                        </span>

                                        <span class="font-light" [class.expire-text]="isInChannel(coupon)"
                                            [class.disabled-interactive-text]="!isInChannel(coupon)"
                                            *ngIf="(coupon.couponInfo[0].status === CouponStatus.COLLECTED) && coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED"
                                            [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                            {{ isInChannel(coupon) ? 'หมดอายุ ' +
                                            (convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime))
                                            : 'ไม่สามารถใช้กับช่องทางนี้ได้' }}
                                        </span>

                                        <span class="font-light expire-text"
                                            *ngIf="(coupon.couponInfo[0].status === CouponStatus.REDEEMED || coupon.couponInfo[0].status === CouponStatus.EXPIRED)"
                                            [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i">
                                            หมดอายุ {{
                                            convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime)
                                            }}
                                        </span>

                                        <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + i" [id]="'showDate' + i"
                                            *ngIf="coupon.couponInfo[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                            {{coupon.couponInfo[0].segmentErrorMessage}}
                                        </span>
                                    </td>

                                    <!-- Check status display button and action of button with parameter from BE  -->

                                    <td #coachMarkButton hidden *ngIf="i == 0"></td>

                                    <td *ngIf="isDisplayButton(coupon.couponInfo[0].status)">
                                        <!-- Allow Action -->
                                        <button *ngIf="coupon.couponInfo[0].status == CouponStatus.REDEEMED"
                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                            [attr.data-testid]="'btn-collected-redeem-' + i"
                                            [id]="'btn-collected-redeem-' + i"
                                            (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCouponForHomePage(coupon, i)">
                                            {{ coupon.numberOfCoupons > 1 ? 'ใช้' : 'ดูโค้ด' }}
                                        </button>

                                        <ng-container
                                            *ngIf="coupon.couponInfo[0].periodStatus === PeriodStatus.ALLOWED else disabledAction">
                                            <button *ngIf="coupon.couponInfo[0].status == CouponStatus.NEW"
                                                class="button font-bold btn-coupon btn-collect is-pulled-right disabled-action-button "
                                                [id]="'btn-collect-' + coupon.couponId"
                                                [attr.data-testid]="'btn-collect-' + i"
                                                (click)="pickCoupon(coupon.couponId, coupon.couponInfo[0].receivedOrder, coupon.campaignCode, i)">
                                                <div class="font-bold">เก็บ</div>
                                            </button>

                                            <ng-container
                                                *ngIf="coupon.couponUseMethod === CouponMethod.COPAY else QRCoupon">
                                                <ng-container *ngIf="isInChannel(coupon);  else NotInChannel">

                                                    <ng-container
                                                        *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED">

                                                        <ng-container
                                                            *ngIf="coupon.isUseButtonDisplay && !coupon.isCoPayNavigate">
                                                            <a #pickCouponCallbackUrl
                                                                class="is-hidden button font-bold btn-coupon btn-redeem is-pulled-right"
                                                                [id]="'btn-redeem-' + i"
                                                                [attr.data-testid]="'btn-redeem-' + i"
                                                                [href]="paymentCallbackUrl.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' | safe : 'url'"
                                                                (click)="setStepRouteFlag(coupon.couponId)">
                                                                ใช้
                                                            </a>

                                                            <!-- Used copay -->
                                                            <button
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useScanCopayCoupon($event, coupon, coupon.couponInfo[0].receivedOrder, 'btn-redeem-' + i, i, 'ScanCoPay')">
                                                                ใช้
                                                            </button>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="!coupon.isUseButtonDisplay && coupon.isCoPayNavigate">
                                                            <ng-container
                                                                *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT">
                                                                <!-- need check destination at ngif or at method -->
                                                                <button
                                                                    *ngIf="coupon.copayNavigation.destination === InternalNavigationType.PT_PAYMENT || 
                                                                    coupon.copayNavigation.destination === InternalNavigationType.PT_TOPUP"
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                    [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : usePaymentTopup(coupon,coupon.couponInfo[0].receivedOrder , i)">
                                                                    ใช้
                                                                </button>

                                                                <button
                                                                    *ngIf="coupon.copayNavigation.destination !== InternalNavigationType.PT_PAYMENT && 
                                                                    coupon.copayNavigation.destination !== InternalNavigationType.PT_TOPUP"
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                    [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : null">
                                                                    ใช้
                                                                </button>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_URL || 
                                                                coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK">
                                                                <!-- external url  -->
                                                                <button
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="prefixId + '-btn-collected-redeem-' + i"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateExternalURL(coupon, i)">
                                                                    ใช้
                                                                </button>
                                                            </ng-container>
                                                        </ng-container>

                                                    </ng-container>
                                                </ng-container>

                                                <ng-template #NotInChannel>
                                                    <button
                                                        *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED"
                                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled">ใช้
                                                    </button>
                                                </ng-template>
                                            </ng-container>

                                            <ng-template #QRCoupon>
                                                <!-- Use QR Code -->
                                                <button *ngIf="coupon.couponInfo[0].status == CouponStatus.COLLECTED"
                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                    [attr.data-testid]="'btn-collected-redeem-' + i"
                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCouponForHomePage(coupon, i)">
                                                    ใช้
                                                </button>
                                            </ng-template>

                                        </ng-container>

                                        <!-- Disabled Action -->

                                        <ng-template #disabledAction>

                                            <button
                                                *ngIf="coupon.couponInfo[0].status === CouponStatus.NEW && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                [attr.data-testid]="'btn-disable-collect-' + i">เก็บ
                                            </button>

                                            <button
                                                *ngIf="coupon.couponInfo[0].status === CouponStatus.COLLECTED && (coupon.couponInfo[0].periodStatus === PeriodStatus.PERIOD || coupon.couponInfo[0].periodStatus === PeriodStatus.DATE)"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                [attr.data-testid]="'btn-disable-redeem-' + i">ใช้
                                            </button>

                                        </ng-template>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- number of coupons check multiple coupon :D -->

                    <div class="badge-number" #badgeNumber
                        [hidden]="!(isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons, coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCoupons > 1)">
                        x{{ isDisplayMultipleCoupons(coupon.couponInfo[0].status, coupon.numberOfCoupons,
                        coupon.couponInfo[0].receivedOrder) ? displayNumberOfQuota(coupon.couponInfo[0].status,
                        coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon,
                        coupon.numberOfOutOfQuotaCoupon,
                        coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) : coupon.numberOfCoupons }}
                        <div class="triangle-shadow"></div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #myCouponTemplate>
    <table class="wrap-content" [attr.data-testid]="'coupon-bottom-dialog'">
        <tr *ngFor="let coupon of coupons; let i = index"
            [id]="couponTab === MyCouponTab.USED_COUPON ? coupon.couponId + '_' + coupon.orders[0].status : coupon.couponId"
            [attr.data-testid]="prefixId + '-card'" #couponCard>

            <ng-container *ngFor="let order of coupon.orders; let j = index">
                <ng-container
                    *ngIf="order.status === CouponStatus.REDEEMED && order.hasCountdownTimer && order.countdownTimeInSeconds > 0">
                    <countdown hidden [config]="{ leftTime: order.countdownTimeInSeconds }"
                        (event)="handleUsedTabCountdownEvent($event, i, j)"></countdown>
                </ng-container>
            </ng-container>

            <td class="coupon-column wrap-coupon" [ngClass]="{'padding-multiple': coupon.numberOfCoupons > 1 }"
                [attr.data-testid]="prefixId + '-link-image-coupon-detail-' + i"
                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">

                <!-- Box shadow check multiple coupon :D -->
                <img *ngIf="coupon.numberOfCoupons > 1" class="mask-left-shadow" [src]="coupon.couponImage"
                    loading="lazy" [attr.data-testid]="'link-coupon-list-detail' + i">

                <img class="mask-left" [src]="coupon.couponImage" loading="lazy"
                    [attr.data-testid]="prefixId + 'link-coupon-list-detail' + i">

                <!-- Stamp coupon need to comfirm status with BE -->
                <div [ngClass]="{
                        'stamp-used': coupon.orders[0].status === CouponStatus.REDEEM_CONFIRMED , 
                        'stamp-expired':  coupon.orders[0].status === CouponStatus.EXPIRED, 
                        'stampsold-out': coupon.orders[0].status === CouponStatus.OUT_OF_QUOTA || coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA,
                        'stamp-unconditional': coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE }">
                </div>

                <!-- Badge Level parameter with BE -->
                <ng-container *ngIf="isDisplayBadgeLabel(coupon.orders[0].status)">
                    <div class="badge badge-green" *ngIf="coupon.badgeLabel === BadgeLabel.NEW">New</div>
                    <div class="badge badge-red" *ngIf="coupon.badgeLabel === BadgeLabel.HOT">Hot</div>
                </ng-container>


            </td>

            <td class="wrap-coupon">
                <div class="shadow-content">
                    <!-- Box shadow check multiple coupon :D -->
                    <div *ngIf="coupon.numberOfCoupons > 1" class="box-content mask-right-shadow"></div>

                    <div class="box-content mask-right">
                        <div class="padding-content box-top-height"
                            [attr.data-testid]="prefixId + '-link-title-coupon-detail-' + i"
                            (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                            <h4 class="newline-wrap coupon-title" [id]="'title-'+i"
                                [ngClass]="{'title-multiple': coupon.numberOfCoupons > 1 }"
                                style="--gap: {{badgeNumber.offsetWidth}}px">{{ coupon.couponShortTitle }}</h4>
                            <span class="newline-wrap coupon-description" [id]="'description-'+i">{{
                                coupon.couponSubtitle
                                }}</span>

                            <!-- Check logic progress bar >= 80 && <= 99 and check parameter with BE -->
                            <div class="section-progress"
                                *ngIf="isDisplayProgressBarLabel(coupon.orders[0].status) && !coupon.isHiddenProgressBar  && coupon.progressionPercent >= 80 && coupon.progressionPercent <= 99 ">
                                <div *ngIf="coupon.deductionType === DeductionType.REDEEMED">
                                    <div class="is-inline">
                                        <progress class="progress orange" [value]="coupon.progressionPercent"
                                            max="100"></progress>
                                        <div class="is-inline-block v-align-top">
                                            <div class="pl-2">
                                                <span class="text-progress">{{coupon.orders[0].status ===
                                                    CouponStatus.COLLECTED ? (
                                                    "ใช้แล้ว"
                                                    ) : (
                                                    coupon.deductionType === "collected" ?
                                                    ( "เก็บแล้ว" ) : ( "ใช้แล้ว" )
                                                    )}}</span>
                                                <span class="text-progress-num orange">
                                                    {{coupon.progressionPercent}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-bottom">
                            <table class="wrap-content"
                                [class.table-fixed]="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                <tr>
                                    <td class="v-align-bottom" width="80%"
                                        [class.coupon-info-ellipsis-text]="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE"
                                        [attr.data-testid]="prefixId + '-link-card-coupon-detail-' + i"
                                        (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                                        <!-- Check status display bottom text with parameter from BE  -->
                                        <span class="font-light expire-text"
                                            [attr.data-testid]="prefixId + '-date-' + i"
                                            *ngIf="coupon.orders[0].status == CouponStatus.REDEEM_CONFIRMED">
                                            <!-- Need to check parameter of used date -->
                                            วันที่ใช้ {{convertDateFormat(coupon.orders[0].redeemedDate)}}
                                        </span>

                                        <span class="font-light expire-text"
                                            [attr.data-testid]="prefixId + '-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.OUT_OF_QUOTA">
                                            ถูกเก็บหมดแล้ว
                                        </span>

                                        <span class="font-light expire-text"
                                            [attr.data-testid]="prefixId + '-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA">
                                            ถูกใช้หมดแล้ว
                                        </span>

                                        <span
                                            *ngIf="(coupon.orders[0].status === CouponStatus.COLLECTED) && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                            class="font-light disabled-interactive-text"
                                            [attr.data-testid]="prefixId + '-date-' + i">
                                            {{ isInChannel(coupon) ? 'เริ่มใช้ได้ ' +
                                            (coupon.orders[0].periodStatus === PeriodStatus.PERIOD
                                            ? (convertTimeFormat(coupon.orders[0].startRedemptionDate) + '-' +
                                            convertTimeFormat(coupon.orders[0].expirationDate))
                                            : (convertDateFormat(coupon.orders[0].startRedemptionDate)))
                                            : 'ไม่สามารถใช้กับช่องทางนี้ได้' }}
                                        </span>

                                        <span class="font-light" [class.expire-text]="isInChannel(coupon)"
                                            [class.disabled-interactive-text]="!isInChannel(coupon)"
                                            *ngIf="((coupon.orders[0].status === CouponStatus.COLLECTED) && coupon.orders[0].periodStatus === PeriodStatus.ALLOWED)"
                                            [attr.data-testid]="prefixId + '-date-' + i" [id]="'showDate' + i">
                                            {{ isInChannel(coupon) ? 'หมดอายุ ' +
                                            (convertDateFormat(coupon.orders[0].expirationDate))
                                            : 'ไม่สามารถใช้กับช่องทางนี้ได้' }}
                                        </span>

                                        <span class="font-light expire-text"
                                            *ngIf="coupon.orders[0].status === CouponStatus.REDEEMED || coupon.orders[0].status === CouponStatus.EXPIRED"
                                            [attr.data-testid]="prefixId + '-date-' + i" [id]="'showDate' + i">
                                            หมดอายุ {{ convertDateFormat(coupon.orders[0].expirationDate) }}
                                        </span>

                                        <span class="font-light expire-text" [attr.data-testid]="prefixId + '-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                            {{coupon.orders[0].segmentErrorMessage}}
                                        </span>
                                    </td>

                                    <!-- Check status display button and action of button with parameter from BE  -->

                                    <td *ngIf="isDisplayButton(coupon.orders[0].status)">

                                        <!-- Allow Action -->

                                        <button *ngIf="coupon.orders[0].status == CouponStatus.REDEEMED"
                                            class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                            [attr.data-testid]="prefixId + '-btn-collected-redeem-' + i"
                                            (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCoupon(coupon, i)">
                                            {{ coupon.numberOfCoupons > 1 ? 'ใช้' : 'ดูโค้ด' }}
                                        </button>

                                        <ng-container
                                            *ngIf="coupon.orders[0].periodStatus === PeriodStatus.ALLOWED else disabledAction">
                                            <ng-container
                                                *ngIf="coupon.couponUseMethod === CouponMethod.COPAY else QRCoupon">

                                                <ng-container *ngIf="isInChannel(coupon); else NotInChannelButton">

                                                    <ng-container
                                                        *ngIf="coupon.orders[0].status == CouponStatus.COLLECTED">

                                                        <ng-container
                                                            *ngIf="coupon.isUseButtonDisplay && !coupon.isCoPayNavigate">

                                                            <a #pickCouponCallbackUrl
                                                                class="is-hidden button font-bold btn-coupon btn-redeem is-pulled-right"
                                                                [id]="'btn-redeem-' + i"
                                                                [attr.data-testid]="'btn-redeem-' + i"
                                                                [href]="paymentCallbackUrl.replace('${campaignId}', coupon.couponId) + '&callbackMode=M' | safe : 'url'"
                                                                (click)="setStepRouteFlag(coupon.couponId)">
                                                                ใช้
                                                            </a>

                                                            <!-- Used copay -->
                                                            <button
                                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useScanCopayCoupon($event, coupon, coupon.orders[0].order, 'btn-redeem-' + i, i, 'ScanCoPay')">
                                                                ใช้
                                                            </button>
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="!coupon.isUseButtonDisplay && coupon.isCoPayNavigate">
                                                            <ng-container
                                                                *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT">

                                                                <button
                                                                    *ngIf="coupon.copayNavigation.destination === InternalNavigationType.PT_PAYMENT ||
                                                                    coupon.copayNavigation.destination === InternalNavigationType.PT_TOPUP"
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : usePaymentTopup(coupon, coupon.orders[0].order , i)">
                                                                    ใช้
                                                                </button>

                                                                <button
                                                                    *ngIf="coupon.copayNavigation.destination !== InternalNavigationType.PT_PAYMENT && 
                                                                    coupon.copayNavigation.destination !== InternalNavigationType.PT_TOPUP"
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="'btn-collected-redeem-' + i"
                                                                    [id]="'btn-collected-redeem-' + coupon.couponId"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : null">
                                                                    ใช้
                                                                </button>

                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK || 
                                                                coupon.copayNavigation.navigateType === CopayNavigationType.EXTERNAL_URL">

                                                                <!-- external url  -->
                                                                <button
                                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                                    [attr.data-testid]="prefixId + '-btn-collected-redeem-' + i"
                                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateExternalURL(coupon, i)">
                                                                    ใช้
                                                                </button>
                                                            </ng-container>

                                                        </ng-container>
                                                    </ng-container>

                                                </ng-container>

                                                <ng-template #NotInChannelButton>
                                                    <button *ngIf="coupon.orders[0].status == CouponStatus.COLLECTED"
                                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled">ใช้
                                                    </button>
                                                </ng-template>

                                            </ng-container>

                                            <ng-template #QRCoupon>
                                                <!-- Use QR Code -->
                                                <button
                                                    *ngIf="coupon.orders[0].status == CouponStatus.COLLECTED"
                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                    [attr.data-testid]="prefixId + '-btn-collected-redeem-' + i"
                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : useCoupon(coupon, i)">
                                                    ใช้
                                                </button>

                                                <!-- external url  -->
                                                <!-- <button
                                                    *ngIf="coupon.orders[0].status == CouponStatus.COLLECTED && isUseExternal"
                                                    class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                    [attr.data-testid]="prefixId + '-btn-collected-redeem-' + i"
                                                    (click)="coupon.numberOfCoupons > 1 ? openOverlay(coupon) : navigateExternalURL(coupon, i)">
                                                    ใช้
                                                </button> -->

                                            </ng-template>

                                        </ng-container>

                                        <!-- Disabled Action -->

                                        <ng-template #disabledAction>

                                            <button
                                                *ngIf="(coupon.orders[0].status === CouponStatus.COLLECTED) && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                [attr.data-testid]="'btn-disable-redeem-' + i">ใช้
                                            </button>

                                        </ng-template>

                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- number of coupons check multiple coupon :D -->

                    <div class="badge-number" #badgeNumber [hidden]="!(coupon.numberOfCoupons > 1)">
                        x{{ coupon.numberOfCoupons }}
                        <div class="triangle-shadow"></div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #couponListTemplate>
    <table class="wrap-content coupon-list-card" [attr.data-testid]="'coupon-bottom-dialog'">
        <tr *ngFor="let coupon of coupons; let i = index" [id]="coupon.couponId + '_' + coupon.orders[0].status"
            #couponCard [attr.data-testid]="'coupon-list-card'" class="scroll-margin-top"
            [class.disabled-card]="isDisplayDisableCard(coupon.orders[0].status, coupon.orders[0].periodStatus, coupon.orders[0].isAboveMinimumSpend )">
            <td class="coupon-column wrap-coupon" [attr.data-testid]="'link-image-coupon-detail-' + i"
                [ngClass]="{'padding-multiple': isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons, coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCollectedCoupons > 1}"
                (click)="coupon.orders[0].status !== CouponStatus.NEW && coupon.orders[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.orders[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCollectedCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                <!-- Box shadow check multiple coupon :D -->
                <div class="mask-img">
                    <img *ngIf="isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons, coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCollectedCoupons > 1"
                        class="mask-left-shadow" [src]="coupon.imageUrl" loading="lazy"
                        [attr.data-testid]="'link-coupon-list-detail' + i">
                    <img class="mask-left" [src]="coupon.imageUrl" loading="lazy"
                        [attr.data-testid]="'link-coupon-list-detail' + i">
                </div>
                <!-- Stamp coupon need to comfirm status with BE -->
                <div [ngClass]="{
                        'stamp-used': coupon.orders[0].status === CouponStatus.REDEEM_CONFIRMED , 
                        'stamp-expired':  coupon.orders[0].status === CouponStatus.EXPIRED, 
                        'stampsold-out': coupon.orders[0].status === CouponStatus.OUT_OF_QUOTA || coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA,
                        'stamp-tempo-sold-out': coupon.orders[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA,
                        'stamp-unconditional': coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE }">
                </div>

                <!-- Badge Level parameter with BE -->
                <ng-container *ngIf="isDisplayBadgeLabel(coupon.orders[0].status)">
                    <div class="badge badge-green" *ngIf="coupon.badgeLabel === BadgeLabel.NEW">New</div>
                    <div class="badge badge-red" *ngIf="coupon.badgeLabel === BadgeLabel.HOT">Hot</div>
                </ng-container>
            </td>

            <td class="wrap-coupon">
                <div class="shadow-content">
                    <!-- Box shadow check multiple coupon :D -->
                    <div *ngIf="isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons, coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCollectedCoupons > 1"
                        class="box-content mask-right-shadow"></div>

                    <div class="box-content mask-right">
                        <div class="padding-content box-top-height" [attr.data-testid]="'link-card-coupon-detail-' + i"
                            (click)="coupon.orders[0].status !== CouponStatus.NEW && coupon.orders[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.orders[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA  && coupon.numberOfCollectedCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">
                            <h4 class="newline-wrap coupon-title" [id]="'title-'+i"
                                [ngClass]="{'title-multiple': isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons, coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCollectedCoupons > 1 }"
                                style="--gap: {{badgeNumber.offsetWidth}}px">{{coupon.couponShortTitle}}</h4>
                            <span class="newline-wrap coupon-description"
                                [id]="'description-'+i">{{coupon.couponSubtitle}}</span>

                            <!-- Check logic progress bar >= 80 && <= 99 and check parameter with BE -->
                            <div class="section-progress"
                                *ngIf="isDisplayProgressBarLabel(coupon.orders[0].status) && !coupon.isHiddenProgressBar && coupon.progressionPercent >= 80 && coupon.progressionPercent <= 99">
                                <div
                                    *ngIf="(coupon.deductionType === DeductionType.COLLECTED && coupon.orders[0].status === CouponStatus.NEW) || coupon.deductionType === DeductionType.REDEEMED">
                                    <div class="is-inline">
                                        <progress class="progress orange" [value]="coupon.progressionPercent"
                                            max="100"></progress>
                                        <div class="is-inline-block v-align-top">
                                            <div class="pl-2">
                                                <span class="text-progress">{{coupon.orders[0].status ===
                                                    CouponStatus.COLLECTED ? (
                                                    "ใช้แล้ว"
                                                    ) : (
                                                    coupon.deductionType === "collected" ?
                                                    ( "เก็บแล้ว" ) : ( "ใช้แล้ว" )
                                                    )}}</span>
                                                <span class="text-progress-num orange">
                                                    {{coupon.progressionPercent}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-bottom">
                            <table class="wrap-content"
                                [class.table-fixed]="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                <tr>
                                    <td class="v-align-bottom" width="80%"
                                        [class.coupon-info-ellipsis-text]="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE"
                                        (click)="coupon.orders[0].status !== CouponStatus.NEW && coupon.orders[0].status !== CouponStatus.OUT_OF_QUOTA && coupon.orders[0].status !== CouponStatus.TEMPO_OUT_OF_QUOTA && coupon.numberOfCollectedCoupons > 1 ? openOverlay(coupon) : navigateToCouponDetail(coupon)">

                                        <!-- Check status display bottom text with parameter from BE  -->
                                        <span class="font-light expire-text"
                                            [attr.data-testid]="'coupon-list-date-' + i"
                                            *ngIf="coupon.orders[0].status == CouponStatus.REDEEM_CONFIRMED">
                                            <!-- Need to check parameter of used date -->
                                            วันที่ใช้ {{convertDateFormat(coupon.orders[0].redemptionEndDateTime)}}
                                        </span>

                                        <span class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-list-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.OUT_OF_QUOTA">
                                            ถูกเก็บหมดแล้ว
                                        </span>

                                        <span class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-list-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA">
                                            ถูกใช้หมดแล้ว
                                        </span>

                                        <span class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-list-date-' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.TEMPO_OUT_OF_QUOTA">
                                            กดเพื่อดูเงื่อนไขการเก็บคูปองในครั้งถัดไป
                                        </span>

                                        <span
                                            *ngIf="coupon.orders[0].status === CouponStatus.NEW && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                            class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-list-date-' + i">
                                            เริ่มเก็บได้ {{ coupon.orders[0].periodStatus === PeriodStatus.PERIOD ?
                                            (convertTimeFormat(coupon.orders[0].couponStartDateTime) + '-' +
                                            convertTimeFormat(coupon.orders[0].couponEndDateTime))
                                            : convertDateFormat(coupon.orders[0].couponStartDateTime) }}
                                        </span>

                                        <span
                                            *ngIf="coupon.orders[0].status === CouponStatus.COLLECTED && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                            class="font-light disabled-interactive-text"
                                            [attr.data-testid]="'coupon-list-date-' + i">
                                            เริ่มใช้ได้ {{ coupon.orders[0].periodStatus === PeriodStatus.PERIOD ?
                                            (convertTimeFormat(coupon.orders[0].redemptionStartDateTime) + '-' +
                                            convertTimeFormat(coupon.orders[0].redemptionEndDateTime))
                                            : convertDateFormat(coupon.orders[0].redemptionStartDateTime) }}
                                        </span>

                                        <ng-container
                                            *ngIf="(coupon.orders[0].status === CouponStatus.NEW || coupon.orders[0].status === CouponStatus.COLLECTED) && coupon.orders[0].periodStatus === PeriodStatus.ALLOWED">
                                            <span *ngIf="coupon.orders[0].isAboveMinimumSpend"
                                                class="font-light expire-text"
                                                [attr.data-testid]="'coupon-list-date-' + i" [id]="'showDate' + i">
                                                หมดอายุ {{ coupon.orders[0].status === CouponStatus.COLLECTED ?
                                                convertDateFormat(coupon.orders[0].redemptionEndDateTime) :
                                                convertDateFormat(coupon.orders[0].couponEndDateTime) }}
                                            </span>

                                            <span *ngIf="!coupon.orders[0].isAboveMinimumSpend"
                                                class="font-light disabled-interactive-text"
                                                [attr.data-testid]="'coupon-list-date-' + i">
                                                ยอดชำระไม่ถึงขั้นต่ำที่กำหนด
                                            </span>
                                        </ng-container>

                                        <span class="font-light expire-text"
                                            *ngIf="(coupon.orders[0].status === CouponStatus.EXPIRED)"
                                            [attr.data-testid]="'coupon-list-date-' + i" [id]="'showDate' + i">
                                            หมดอายุ {{ convertDateFormat(coupon.orders[0].couponEndDatetime) }}
                                        </span>

                                        <span class="font-light expire-text" [attr.data-testid]="'coupon-list-date-' + i" [id]="'showDate' + i"
                                            *ngIf="coupon.orders[0].status === CouponStatus.NOT_ELIGIBLE_TO_USE">
                                            {{coupon.orders[0].segmentErrorMessage}}
                                        </span>
                                    </td>

                                    <!-- Check status display button and action of button with parameter from BE  -->

                                    <td *ngIf="isDisplayButton(coupon.orders[0].status)">
                                        <ng-container
                                            *ngIf="coupon.orders[0].periodStatus === PeriodStatus.ALLOWED else disabledAction">
                                            <button *ngIf="coupon.orders[0].status == CouponStatus.NEW"
                                                class="button font-bold btn-coupon btn-collect is-pulled-right disabled-action-button"
                                                [id]="'btn-collect-' + coupon.couponId"
                                                [attr.data-testid]="'btn-collect-' + i"
                                                (click)="pickCoupon(coupon.couponId, coupon.orders[0].order, coupon.campaignCode, i)">
                                                <div class="font-bold">เก็บ</div>
                                            </button>

                                            <ng-container *ngIf="coupon.orders[0].status == CouponStatus.COLLECTED">
                                                <ng-container
                                                    *ngIf="coupon.orders[0].isAboveMinimumSpend else belowMinimumSpendCollected">
                                                    <a #pickCouponCallbackUrl
                                                        class="is-hidden button font-bold btn-coupon btn-redeem is-pulled-right"
                                                        [id]="'btn-redeem-' + i" [attr.data-testid]="'btn-redeem-' + i"
                                                        [href]="chooseCouponCallbackUrl.replace('${campaignId}', coupon.couponId) | safe : 'url'"
                                                        (click)="setStepRouteFlag(coupon.couponId)">
                                                        ใช้
                                                    </a>

                                                    <button
                                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                                        [attr.data-testid]="'btn-collected-redeem-' + i"
                                                        (click)="coupon.numberOfCollectedCoupons > 1 ? openOverlay(coupon) : useScanCopayCoupon($event, coupon, coupon.orders[0].order, 'btn-redeem-' + i, i)">
                                                        ใช้
                                                    </button>

                                                </ng-container>
                                                <ng-template #belowMinimumSpendCollected>
                                                    <button
                                                        class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                        [attr.data-testid]="'btn-disable-redeem-' + i">ใช้
                                                    </button>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>

                                        <!-- Disabled Action -->

                                        <ng-template #disabledAction>
                                            <button
                                                *ngIf="coupon.orders[0].status === CouponStatus.NEW && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                [attr.data-testid]="'btn-disable-collect-' + i">เก็บ
                                            </button>
                                            <button
                                                *ngIf="coupon.orders[0].status === CouponStatus.COLLECTED && (coupon.orders[0].periodStatus === PeriodStatus.PERIOD || coupon.orders[0].periodStatus === PeriodStatus.DATE)"
                                                class="button font-bold btn-coupon btn-redeem is-pulled-right disabled"
                                                [attr.data-testid]="'btn-disable-redeem-' + i">ใช้
                                            </button>
                                        </ng-template>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- number of coupons check multiple coupon :D -->
                    <div class="badge-number" #badgeNumber
                        [hidden]="!(isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons, coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status, coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon, coupon.numberOfOutOfQuotaCoupon, coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) > 1 : coupon.numberOfCollectedCoupons > 1)">
                        x{{ isDisplayMultipleCoupons(coupon.orders[0].status, coupon.numberOfCollectedCoupons,
                        coupon.orders[0].order) ? displayNumberOfQuota(coupon.orders[0].status,
                        coupon.repetitiveStatus, coupon.numberOfTemporaryOutOfQuotaCoupon,
                        coupon.numberOfOutOfQuotaCoupon,
                        coupon.maximumRepetitiveCouponPerUser, coupon.maximumCouponPerUser) :
                        coupon.numberOfCollectedCoupons }}
                        <div class="triangle-shadow"></div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</ng-template>