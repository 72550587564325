import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICouponResponse, IFetchCouponData, IFetchCouponDetail, IFetchMyCouponData, IFetchCouponList, IFetchRedeemCoupon, IFetchCollectCoupon, IFetchReseverCouponOrder, IFetchCompleteCoachMark, ICustomerTutorial, ICouponListModel, ICouponModel, IPermissionUseCouponResponse, ICouponCategory } from '../model/coupon-model.model';

export const saveAccressToken = createAction(
  '[Coupon Platform] Save Access Token',
  props<{ accressToken: string; }>()
);

export const loadCoupons = createAction(
  '[Coupon Platform] Load Coupons',
  props<{ isLoading: boolean, isLoadingCollectedSuccess: boolean }>()
);

export const loadCouponsSuccess = createAction(
  '[Coupon Platform] Load Coupons Success',
  props<{ data: ICouponResponse<IFetchCouponData> }>()
);

export const loadCouponDetail = createAction(
  '[Coupon Platform] Load Coupon Detail',
  props<{ isLoading: boolean, isQRCode: boolean, isLoadingCollectedSccuess: boolean }>()
);

export const loadCouponDetailSuccess = createAction(
  '[Coupon Platform] Load Coupon Detail Success',
  props<{ data: ICouponResponse<IFetchCouponDetail> }>()
);

export const loadPermissionUseCoupon = createAction(
  '[Coupon Platform] Load Permission Use Coupon',
  props<{ couponId: string, couponOrder: number, checkStatus: boolean }>()
);

export const loadPermissionUseCouponSuccess = createAction(
  '[Coupon Platform] Load Permission Use Coupon Success',
  props<{ data: IPermissionUseCouponResponse }>()
);

export const loadPermissionUseCouponFailure = createAction(
  '[Coupon Platform] Load Permission Use Coupon Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearPermissionUseCoupon = createAction(
  '[Coupon Platform] Clear Permission Use Coupon',
);

export const loadMyCoupons = createAction(
  '[Coupon Platform] Load MyCoupons',
  props<{ isLoading: boolean }>()
);

export const loadMyCouponSuccess = createAction(
  '[Coupon Platform] Load MyCoupons Success',
  props<{ data: ICouponResponse<IFetchMyCouponData> }>()
);

export const loadCouponList = createAction(
  '[Coupon Platform] Load Coupon List',
  props<{ amount: string, isLoading: boolean, couponId?: string }>()
);

export const loadCouponListSuccess = createAction(
  '[Coupon Platform] Load Coupon List Success',
  props<{ data: ICouponResponse<IFetchCouponList> }>()
);

export const loadCollectedCouponListSuccess = createAction(
  '[Coupon Platform] Load Collected Coupon List Success',
  props<{ data: ICouponListModel[] }>()
);

export const loadCollectCoupon = createAction(
  '[Coupon Platform] Load Collect Coupon',
  props<{ couponId: string, couponOrder?: number }>()
);

export const loadCollectCouponSuccess = createAction(
  '[Coupon Platform] Load Collect Coupon Success',
  props<{ data: ICouponResponse<IFetchCollectCoupon> }>()
);

export const loadCollectCouponFailure = createAction(
  '[Coupon Platform] Load Collect Coupon Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearCollectCoupon = createAction(
  '[Coupon Platform] Clear Collect Coupon'
);

export const loadRedeemCoupon = createAction(
  '[Coupon Platform] Load Redeem Coupon'
);

export const clearRedeemCoupon = createAction(
  '[Coupon Platform] Clear Redeem Coupon'
)

export const loadRedeemCouponSuccess = createAction(
  '[Coupon Platform] Load Redeem Coupon Success',
  props<{ data: ICouponResponse<IFetchRedeemCoupon> }>()
);

export const loadRedeemCouponFailure = createAction(
  '[Coupon Platform] Load Redeem Coupon Failure',
  props<{ error: HttpErrorResponse }>()
);

export const loadConfirmRedeemCoupon = createAction(
  '[Coupon Platform] Load Confirm Redeem Coupon',
  props<{ couponOrder: number }>()
);

export const clearCouponDetail = createAction(
  '[Coupon Platform] Clear Coupon Detail'
);

export const loadReserveCouponOrder = createAction(
  '[Coupon Platform] Load Reserve Coupon Order',
  props<{ couponId: string, couponOrder: number, mode?: string }>()
);

export const loadReserveCouponOrderSuccess = createAction(
  '[Coupon Platform] Load Reserve Coupon Order Success',
  props<{ data: IFetchReseverCouponOrder }>()
);

export const loadReserveCouponOrderFailure = createAction(
  '[Coupon Platform] Load Reserve Coupon Order Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearReserveCouponOrder = createAction(
  '[Coupon Platform] Clear Reserve Coupon Order'
);

export const loadGetCustomerTutorialCoachMark = createAction(
  '[Coupon Platform] Load Get Customer Tutorial Coach Mark'
);
export const loadGetCustomerTutorialCoachMarkSuccess = createAction(
  '[Coupon Platform] Load Get Customer Tutorial Coach Mark Success',
  props<{ data: ICouponResponse<ICustomerTutorial> }>()
);

export const loadUpdateCustomerTutorialCoachMark = createAction(
  '[Coupon Platform] Load Update Customer Tutorial Coach Mark'
);

export const loadUpdateCustomerTutorialCoachMarkSuccess = createAction(
  '[Coupon Platform] Load Update Customer Tutorial Coach Mark Success',
  props<{ data: IFetchCompleteCoachMark }>()
);

export const loadCouponsCategory = createAction(
  '[Coupon Platform] Load Coupon Category',
)

export const loadCouponsCategorySuccess = createAction(
  '[Coupon Platform] Load Coupon Category Success',
  props<{ data: ICouponCategory[] }>()
)

export const loadSeachCoupon = createAction(
  '[Coupon Platform] Load Search Coupon',
  props<{ keyword: string, isLoadingCollectedSuccess: boolean }>()
)

export const loadSeachCouponSuccess = createAction(
  '[Coupon Platform] Load Search Coupon Success',
  props<{ data: ICouponModel[] }>()
)

export const saveSeachKeyword = createAction(
  '[Coupon Platform] Save Search Keyword',
  props<{ keyword: string }>()
)

export const saveDefaultSeachKeyword = createAction(
  '[Coupon Platform] Save Default Search Keyword',
  props<{ defaultSearchKeyword: string[] }>()
)

export const saveDefaultSeachKeywordIndex = createAction(
  '[Coupon Platform] Save Default Search Keyword Index',
  props<{ defaultSearchKeywordIndex: number }>()
)

export const clearSeachCoupon = createAction(
  '[Coupon Platform] Clear Search Coupon'
)

export const clearSearchCouponResult = createAction(
  '[Coupon Platform] Clear Search Coupon Result'
)
