import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonModal, IModalTemplate } from 'src/app/model/coupon-modal.model';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit {
  @Output() onActionReturn: EventEmitter<any> = new EventEmitter<any>();
  couponChannel = this.rootStoreService.jwtSession!.couponChannel;
  selectedCoupon: SelectedCoupon | undefined;
  modalObj: IModalTemplate = {
    title: "",
    detail: "",
    analyticModal: {
      eventCategory: '',
      firebaseParam: undefined,
      useNativeInterface: false
    },
    button: [],
    isFullWidthBtn: false,
    deeplinkCallbackUrl: undefined,
    templateName: ''
  };

  isMultiButton: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalTemplateComponent>,
    private rootStoreService: RootStoreService,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) { }

  ngOnInit(): void {
    this.openModal();
  }

  openModal() {
    this.modalObj.title = this.data.title;
    this.modalObj.detail = this.data.detail;
    this.modalObj.analyticModal = this.data.analyticModal;
    this.modalObj.button = this.data.button;
    this.modalObj.isFullWidthBtn = this.data.isFullWidthBtn || false;
    this.modalObj.deeplinkCallbackUrl = this.data.deeplinkCallbackUrl ?? undefined;
    this.modalObj.templateName = this.data.templateName ?? undefined;

    if (this.data.button.length > 1) {
      this.setGATag(`${this.data.title}//${this.data.detail}`, AnalyticAction.POPUP);
      this.isMultiButton = true;
    } else {
      this.setGATag(`${this.data.title}//${this.data.detail}`, AnalyticAction.ERROR);
      this.isMultiButton = false;
    }
  }

  closeModal(val: ButtonModal, isSetGATag: boolean = true) {
    this.dialogRef.close(val.action);

    if (isSetGATag) {
      this.setGATag(val.eventLabel);
    }
  }

  clickAction(val: ButtonModal) {
    this.closeModal(val, false);
    this.onActionReturn.emit(val.action);
    this.setGATag(val.eventLabel);
  }

  setGATag(eventLabel: string, eventAction?: string) {
    const gaObj: CouponEventTagAction = {
      eventName: this.modalObj.analyticModal.eventCategory ?? '',
      eventCategory: this.modalObj.analyticModal.eventCategory ?? '',
      eventLabel: `${this.modalObj.analyticModal.eventCategory}_${eventLabel}`,
      eventAction: eventAction ?? AnalyticAction.CLICK,
      campaignCode: this.modalObj.analyticModal.firebaseParam?.find(k => k.key === AnalyticFirebaseParam.CAMPAIGN_ID)?.value ?? '',
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackActionGATag(gaObj)

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: eventAction ?? AnalyticAction.CLICK,
        category: this.modalObj.analyticModal.eventCategory ?? '',
        label: `${this.modalObj.analyticModal.eventCategory}_${eventLabel}`,
        firebase_screen: this.modalObj.analyticModal.eventCategory ?? '',
      },
      this.modalObj.analyticModal.firebaseParam,
      this.modalObj.analyticModal.useNativeInterface
    );
  }
}
