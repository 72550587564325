<div [class]="'header has-text-centered' + ((path && path !== PageRoute.QR_CODE) && withPadding ? '' : ' without-header')">
    <div class="item"  *ngIf="path && path !== PageRoute.QR_CODE">
        <!-- home -->
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
            'width': '80px',
            'height': '25px',
            'margin-top': '8px',
            'background': '#85F5F5',
            'border-radius': '8px'
          }"></ngx-skeleton-loader>

          <!-- mycoupon -->
        <!-- <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
            'width': '80px',
            'height': '25px',
            'margin-top': '8px',
            'margin-bottom': '8px',
            'background': '#85F5F5',
            'border-radius': '8px'
          }"></ngx-skeleton-loader> -->
        <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
    </div>

    <div class="has-text-left back-btn">
        <a class="link-item" data-testid="link-back-icon"></a>
    </div>
</div>