import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import * as RootStoreActions from './root-actions';
import * as CouponActions from '../store/coupon.actions';
import { AppState, JWTSession, SelectedCoupon } from './root-store.model';
import { Store } from '@ngrx/store';
import * as Lodash from "lodash";
import { UtilService } from '../service/util.service';
import { EMPTY, Subject } from 'rxjs';
import { RootStoreService } from './root-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PageRoute } from '../configs/route-config';
import { Location } from '@angular/common';


@Injectable()
export class RootStoreEffects {
  private _destroy = new Subject<void>();
  isDestroy = false;
  triggerDestroyRootEffect() {
    if (this.isDestroy) return;

    this.isDestroy = true;
    this._destroy.next();
    this._destroy.complete();
  }

  private manageStepRouteConfigFromStorage() {
    const stepRouteConfig: string[] = localStorage.getItem("stepRouteConfig") ? JSON.parse(localStorage.getItem("stepRouteConfig") ?? "") : [];

    if (Lodash.isEmpty(stepRouteConfig))
      this.store.dispatch(RootStoreActions.clearStepRouteConfig())
    else
      this.store.dispatch(RootStoreActions.saveStepRouteConfig({ routesUrl: stepRouteConfig }));
  }

  initialization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RootStoreActions.initialization),
      mergeMap(() =>
        this.activatedRoute.queryParams.pipe(
          filter((v) => {
            return ((v['authorizationCode'] && v['webConfig']) || v['code'] || sessionStorage.getItem('codeToken'));
          }),
          takeUntil(this._destroy),
          tap((data) => {

            /* New Flow Authentication */
            if (data['webConfig'] && data['authorizationCode']) {

              sessionStorage.removeItem('accessToken');
              this.store.dispatch(CouponActions.saveAccressToken({ accressToken: "" }));
              this.store.dispatch(RootStoreActions.postAuthVerify({ authorizationCode: data['authorizationCode'], webConfig: data['webConfig'] }))

              if (localStorage.getItem("navigateExternalPage")) {
                localStorage.setItem("navigateExternalPageTemp", localStorage.getItem("navigateExternalPage")!)
                if (data['couponId'] && (data['couponId'] !== localStorage.getItem("navigateExternalPage"))) {
                  localStorage.removeItem("stepRouteConfig");
                  localStorage.removeItem("selectedCoupon");
                } else {
                  if (this.location.path().includes(PageRoute.PATH_COUPON_DETAIL)) {
                    sessionStorage.setItem("checkCouponDetailStatus", "true");
                  }
                }
                localStorage.removeItem("navigateExternalPage");
              } else {
                localStorage.removeItem("amount");
                localStorage.removeItem("stepRouteConfig");
                localStorage.removeItem("onCheckedTab");
                localStorage.removeItem("selectedCoupon");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("searchKeyword");
                localStorage.removeItem("navigateExternalPageTemp");
              }

              this.manageStepRouteConfigFromStorage();
              return;
            }
            /* New Flow Authentication */

            /* Existing Flow Authentication */
            if (data['code']) {
              sessionStorage.setItem('codeToken', data['code']);
              sessionStorage.removeItem('accessToken');
              this.store.dispatch(CouponActions.saveAccressToken({ accressToken: "" }));
              if (localStorage.getItem("navigateExternalPage")) {
                localStorage.setItem("navigateExternalPageTemp", localStorage.getItem("navigateExternalPage")!)
                if (data['couponId'] && (data['couponId'] !== localStorage.getItem("navigateExternalPage"))) {
                  localStorage.removeItem("stepRouteConfig");
                  localStorage.removeItem("selectedCoupon");
                } else {
                  if (this.location.path().includes(PageRoute.PATH_COUPON_DETAIL)) {
                    sessionStorage.setItem("checkCouponDetailStatus", "true");
                  }
                }
                localStorage.removeItem("navigateExternalPage");
              } else {
                localStorage.removeItem("amount");
                localStorage.removeItem("stepRouteConfig");
                localStorage.removeItem("onCheckedTab");
                localStorage.removeItem("selectedCoupon");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("searchKeyword");
                localStorage.removeItem("navigateExternalPageTemp");
              }
            }

            this.manageStepRouteConfigFromStorage();
            const webToken: string = sessionStorage.getItem('codeToken')!;

            if (webToken) {

              const parseWebToken = this.utilService.parseJwt(JSON.stringify(webToken));

              const jwtSession: JWTSession = {
                couponChannel: parseWebToken.channelId,
                withHeader: parseWebToken.withHeader,
                status: parseWebToken.status,
                registerCallbackUrl: parseWebToken.registerCallbackUrl ?? "",
                processingCallbackUrl: parseWebToken.processingCallbackUrl ?? "",
                paymentCallbackUrl: parseWebToken.paymentCallbackUrl ?? "",
                chooseCouponCallbackUrl: parseWebToken.chooseCouponCallbackUrl ?? "",
                callbackUrl: parseWebToken.callbackUrl ?? "",
                mode: parseWebToken.mode ?? "",
                webTokenId: parseWebToken.webTokenId ?? "",
                sourceOfFund: parseWebToken.sourceOfFund ?? "",
                callbackDestination: parseWebToken.callbackDestination ?? "",
                entryPoint: parseWebToken.entryPoint ?? "",
                paymentMethod: parseWebToken.paymentMethod ?? ""
              };

              this.store.dispatch(
                RootStoreActions.saveWebToken({ webToken, jwtSession })
              );

              const accessToken = sessionStorage.getItem('accessToken');
              if (accessToken) {
                sessionStorage.setItem('accessToken', accessToken);
                this.store.dispatch(CouponActions.saveAccressToken({ accressToken: accessToken }));
              }
            }

            /* Existing Flow Authentication */
          }),
          map(({ couponId, couponCode, hasCountdownTimer, countdownTimeInSeconds, order, previousurl }) => {

            if (Lodash.isEmpty(couponId)) return;

            if (localStorage.getItem("selectedCategory")) {
              const selectedCategory = localStorage.getItem("selectedCategory");
              if (!Lodash.isEmpty(selectedCategory)) {
                this.store.dispatch(RootStoreActions.saveSelectedCategoryId({ selectedCategoryId: selectedCategory! })
                );
              }
              localStorage.removeItem("selectedCategory");
            }

            if (this.location.path().includes(PageRoute.PATH_HOME) || this.location.path().includes(PageRoute.PATH_MY_COUPON)) {
              const retrievedObjectCoupon = localStorage.getItem("selectedCoupon");
              if (!Lodash.isEmpty(retrievedObjectCoupon)) {
                const selectedCouponStore = Lodash.cloneDeep(JSON.parse(retrievedObjectCoupon!));
                const selectedCoupon: SelectedCoupon = {
                  couponId: selectedCouponStore.couponId,
                  couponCode: selectedCouponStore.couponCode,
                  hasCountdownTimer: selectedCouponStore.hasCountdownTimer,
                  countdownTimeInSeconds: selectedCouponStore.countdownTimeInSeconds,
                  order: selectedCouponStore.order,
                  couponStatus: selectedCouponStore.couponStatus
                };
                this.store.dispatch(RootStoreActions.saveSelectedCoupon({ selectedCoupon: selectedCoupon, previousurl: "" }));
                localStorage.removeItem("selectedCoupon");

                const searchKeyword = localStorage.getItem("searchKeyword");
                if (searchKeyword && this.location.path().includes(PageRoute.PATH_HOME)) {
                  this.store.dispatch(CouponActions.saveSeachKeyword({ keyword: searchKeyword }));
                  localStorage.removeItem("searchKeyword");
                  this.router.navigateByUrl(`${PageRoute.PATH_SEARCH_COUPON}`, { replaceUrl: true });
                }
                return;
              }
            } else {
              const selectedCoupon: SelectedCoupon = {
                couponId: couponId,
                couponCode: couponCode ?? '',
                hasCountdownTimer: hasCountdownTimer ?? false,
                countdownTimeInSeconds: countdownTimeInSeconds ?? 0,
                order: order ?? ''
              }
              this.store.dispatch(RootStoreActions.saveSelectedCoupon({ selectedCoupon, previousurl }));

              const searchKeyword = localStorage.getItem("searchKeyword");
              if (searchKeyword) {
                this.store.dispatch(CouponActions.saveSeachKeyword({ keyword: searchKeyword }));
              }
            }
          })
        )
      )
    );
  }, { dispatch: false });

  postAuthVerify$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RootStoreActions.postAuthVerify),
      mergeMap((action) =>
        this.rootStoreService.postAuthVerify(
          action.authorizationCode, action.webConfig
        ).pipe(
          tap((response) => {
            this.triggerDestroyRootEffect();
            if (response.data.accessToken) {
              sessionStorage.setItem('accessToken', response.data.accessToken);
              this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))

              sessionStorage.setItem('channelId', 'PT');

              const parseJTWToken = this.utilService.parseJwt(JSON.stringify(response.data.accessToken));
              const jwtSession = this.praseJWTWebCoew(parseJTWToken.webConfig);

              this.store.dispatch(RootStoreActions.saveJWTSession({ jwtSession }));

              this.store.dispatch(
                RootStoreActions.saveWebToken({ webToken: "", jwtSession })
              );
            }

          }),
          catchError((err: HttpErrorResponse) => {
            sessionStorage.removeItem('codeToken');
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('channelId');
            if ((err?.error?.result?.code == 8000) || (err.status == 403) || (err.status == 503)) {
              this.router.navigateByUrl(PageRoute.PATH_UNDER_MAINTENANCE);
            } else {
              this.router.navigateByUrl(PageRoute.PATH_UNAUTHORIZED);
            }
            return EMPTY;
          })
        )
      )
    );
  }, { dispatch: false }
  );

  private praseJWTWebCoew(parseWebToken: any) {
    const jwtSession: JWTSession = {
      couponChannel: parseWebToken.channelId,
      withHeader: true,
      status: parseWebToken.status,
      registerCallbackUrl: parseWebToken.registerCallbackUrl ?? "",
      processingCallbackUrl: parseWebToken.processingCallbackUrl ?? "",
      paymentCallbackUrl: parseWebToken.paymentCallbackUrl ?? "",
      chooseCouponCallbackUrl: parseWebToken.chooseCouponCallbackUrl ?? "",
      callbackUrl: parseWebToken.callbackUrl ?? "",
      mode: parseWebToken.mode ?? "",
      webTokenId: parseWebToken.webTokenId ?? "",
      sourceOfFund: parseWebToken.sourceOfFund ?? "",
      callbackDestination: parseWebToken.callbackDestination ?? "",
      entryPoint: parseWebToken.entryPoint ?? "",
      paymentMethod: parseWebToken.paymentMethod ?? ""
    };
    return jwtSession;
  }

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private store: Store<AppState>,
    private rootStoreService: RootStoreService,
    private router: Router,
    private location: Location

  ) { }
}
