import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isNil } from 'lodash';
import { filter, Subject, takeUntil } from 'rxjs';
import { PageRoute } from 'src/app/configs/route-config';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {

  @ViewChild('loadingPage') loadingPage: any;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  path: PageRoute;
  withPadding: boolean = true; // this.rootStoreService.jwtSession?.withHeader ?? true;
  PageRoute = PageRoute;
  loading: boolean = false;
  private _destroy = new Subject<void>();


  constructor(
    private utilService: UtilService,
  ) {
    this.utilService.loadingStatus.pipe(filter((v: any) => !isNil(v))).subscribe((value: any) => {
      this.path = value.pageRoute;

      if (this.path === PageRoute.SEARCH_COUPON) {
        this.loadingPage.nativeElement.hidden = true;
        return;
      }

      if (this.loading == !value.isloading) {
        this.toggleContent(value.isloading);
      }
    })
  }

  ngOnInit(): void {

  }

  toggleContent(val: any) {
    this.loading = val;

    if (this.loading) {
      this.loadingPage.nativeElement.hidden = false;
    } else {
      this.loadingPage.nativeElement.hidden = true;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
