import { createAction, props } from '@ngrx/store';
import { JWTSession, SelectedCoupon } from './root-store.model';

export const initialization = createAction(
  '[Root Coupon Platform] Initialization'
);

export const saveJWTSession = createAction(
  '[Root Coupon Platform] Save JWT Session',
  props<{ jwtSession: JWTSession }>()
);

export const saveWebToken = createAction(
  '[Root Coupon Platform] Save Web Token',
  props<{ webToken: string, jwtSession: JWTSession }>()
);

export const saveSelectedCoupon = createAction(
  '[Root Coupon Platform] Save Selected Coupon',
  props<{ selectedCoupon: SelectedCoupon, previousurl: string; }>()
);

export const clearSelectedCoupon = createAction(
  '[Root Coupon Platform] Clear Selected Coupon'
);

export const savePreviousUrl = createAction(
  '[Root Coupon Platform] Save Previous URL',
  props<{ previousurl: string }>()
);

export const saveCurrentPage = createAction(
  '[Root Coupon Platform] Save Current Page',
  props<{ currentPage: string }>()
);

export const saveStepRouteConfig = createAction(
  '[Root Coupon Platform] Save Step Route Config',
  props<{ routesUrl: string[] }>()
);

export const removeStepRouteConfig = createAction(
  '[Root Coupon Platform] Remove Step Route Config',
  props<{ routeUrl: string }>()
);

export const clearStepRouteConfig = createAction(
  '[Root Coupon Platform] Clear Step Route Config'
);

export const saveSelectedCategoryId = createAction(
  '[Root Coupon Platform] Save Selected CategoryId',
  props<{ selectedCategoryId: string }>()
);

export const postAuthVerify = createAction(
  '[Root Coupon Platform] Post Auth Verify',
  props<{ authorizationCode: string, webConfig: string }>()
);



