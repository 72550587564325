import { ICouponCategory, ICouponListModel, ICouponModel, ICouponResponse, ICouponSearch, ICustomerTutorial, IFetchCollectCoupon, IFetchCompleteCoachMark, IFetchConfirmRedeemCoupon, IFetchCouponData, IFetchCouponDetail, IFetchCouponList, IFetchMyCouponData, IFetchRedeemCoupon, IFetchReseverCouponOrder, IPermissionUseCouponResponse } from "./coupon-model.model";

export const COUPON_STATE = 'counpon';

export interface CouponState {
    isInitialised: boolean;
    isLoading: boolean;
    accressToken: string;
    customerTutorial: ICouponResponse<ICustomerTutorial>;
    coupons: ICouponResponse<IFetchCouponData>;
    couponDetail: ICouponResponse<IFetchCouponDetail>;
    myCoupon: ICouponResponse<IFetchMyCouponData>;
    couponList: ICouponResponse<IFetchCouponList>;
    collectCoupon: ICouponResponse<IFetchCollectCoupon>;
    redeemCoupon: ICouponResponse<IFetchRedeemCoupon>;
    confirmRedeemCoupon: IFetchConfirmRedeemCoupon;
    reserveCouponOrder: IFetchReseverCouponOrder;
    completeCoachMark: IFetchCompleteCoachMark;
    permissionUseCoupon: IPermissionUseCouponResponse;
    categories: ICouponCategory[];
    couponSearch: ICouponSearch;
}
