import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as RootStoreActions from './root-actions';
import * as RootStoreType from './root-store.model';

export const initialState: RootStoreType.Session = {
  isInitialised: false,
  webToken: '',
  jwtSession: {
    couponChannel: '',
    status: '',
    callbackUrl: '',
    registerCallbackUrl: '',
    processingCallbackUrl: '',
    paymentCallbackUrl: '',
    chooseCouponCallbackUrl: '',
    withHeader: undefined,
    mode: '',
    webTokenId: '',
    sourceOfFund: '',
    callbackDestination: '',
    entryPoint: '',
    paymentMethod: ''
  },
  previousUrl: '',
  currentPage: '',
  stepRouteConfig: [],
  selectedCoupon: {
    couponId: '',
    couponCode: '',
    hasCountdownTimer: false,
    countdownTimeInSeconds: 0,
    order: 0,
    couponStatus: undefined
  },
  selectedCategoryId: '0'
};

export const sessionReducer = createReducer<RootStoreType.AppState['session']>(
  initialState,
  on(RootStoreActions.saveSelectedCoupon, (state, action) => {
    return {
      ...state,
      previousUrl: action.previousurl,
      selectedCoupon: {
        couponId: action.selectedCoupon.couponId,
        couponCode: action.selectedCoupon.couponCode,
        hasCountdownTimer: action.selectedCoupon.hasCountdownTimer,
        countdownTimeInSeconds: action.selectedCoupon.countdownTimeInSeconds,
        order: action.selectedCoupon.order,
        couponStatus: action.selectedCoupon.couponStatus,
        formPage: action.selectedCoupon.formPage ?? undefined
      }
    };
  }),
  on(RootStoreActions.clearSelectedCoupon, (state, action) => {
    return {
      ...state,
      previousUrl: '',
      selectedCoupon: {
        couponId: '',
        couponCode: '',
        hasCountdownTimer: false,
        countdownTimeInSeconds: 0,
        order: 0,
        couponStatus: undefined
      },
      selectedCategoryId: '0'
    };
  }),
  on(RootStoreActions.saveWebToken, (state, action) => {
    return {
      ...state,
      isInitialised: true,
      webToken: action.webToken,
      jwtSession: {
        couponChannel: action.jwtSession.couponChannel,
        status: action.jwtSession.status,
        callbackUrl: action.jwtSession.callbackUrl,
        registerCallbackUrl: action.jwtSession.registerCallbackUrl,
        processingCallbackUrl: action.jwtSession.processingCallbackUrl,
        paymentCallbackUrl: action.jwtSession.paymentCallbackUrl,
        chooseCouponCallbackUrl: action.jwtSession.chooseCouponCallbackUrl,
        withHeader: action.jwtSession.withHeader,
        mode: action.jwtSession.mode,
        webTokenId: action.jwtSession.webTokenId,
        sourceOfFund: action.jwtSession.sourceOfFund,
        callbackDestination: action.jwtSession.callbackDestination,
        entryPoint: action.jwtSession.entryPoint,
        paymentMethod: action.jwtSession.paymentMethod
      }
    };
  }),
  on(RootStoreActions.saveJWTSession, (state, action) => {
    return {
      ...state,
      jwtSession: {
        couponChannel: action.jwtSession.couponChannel,
        status: action.jwtSession.status,
        callbackUrl: action.jwtSession.callbackUrl,
        registerCallbackUrl: action.jwtSession.registerCallbackUrl,
        processingCallbackUrl: action.jwtSession.processingCallbackUrl,
        paymentCallbackUrl: action.jwtSession.paymentCallbackUrl,
        chooseCouponCallbackUrl: action.jwtSession.chooseCouponCallbackUrl,
        withHeader: action.jwtSession.withHeader,
        mode: action.jwtSession.mode,
        webTokenId: action.jwtSession.webTokenId,
        sourceOfFund: action.jwtSession.sourceOfFund,
        callbackDestination: action.jwtSession.callbackDestination,
        entryPoint: action.jwtSession.entryPoint,
        paymentMethod: action.jwtSession.paymentMethod
      }
    };
  }),
  on(RootStoreActions.savePreviousUrl, (state, action) => {
    return {
      ...state,
      previousUrl: action.previousurl
    }
  }),
  on(RootStoreActions.saveCurrentPage, (state, action) => {
    return {
      ...state,
      currentPage: action.currentPage
    }
  }),
  on(RootStoreActions.saveStepRouteConfig, (state, action) => {

    const isExisting = action.routesUrl.length > 1 ? false : state.stepRouteConfig.includes(action.routesUrl[0]);
    let stateRouting = cloneDeep(state.stepRouteConfig);

    if (isExisting) {
      stateRouting = state.stepRouteConfig.filter((routes) => routes !== action.routesUrl[0])
    }

    const stepRouteConfig = {
      ...state,
      stepRouteConfig: [...new Set([...stateRouting, ...action.routesUrl])]
    };

    localStorage.setItem("stepRouteConfig", JSON.stringify(stepRouteConfig.stepRouteConfig));

    return stepRouteConfig;
  }),

  on(RootStoreActions.removeStepRouteConfig, (state, action) => {

    const stepRouteConfig = {
      ...state,
      stepRouteConfig: state.stepRouteConfig.filter((routes) => routes !== action.routeUrl)
    };

    localStorage.setItem("stepRouteConfig", JSON.stringify(stepRouteConfig.stepRouteConfig));

    return stepRouteConfig;
  }),

  on(RootStoreActions.clearStepRouteConfig, (state, action) => {
    localStorage.removeItem("stepRouteConfig");
    return {
      ...state,
      stepRouteConfig: []
    };
  }),
  on(RootStoreActions.saveSelectedCategoryId, (state, action) => {
    return {
      ...state,
      selectedCategoryId: action.selectedCategoryId
    };
  }),
);
