<div [class]="isAndroid ? ' android-device' : ''">
  <section class="modal-card-body modal-card-bottom-dialog">
    <div class="columns is-gapless is-mobile mb-16">
      <div class="column is-three-quarters pb-0 ">
        <h2 class="text-title-confirm">{{ couponNumber }} {{titleDialog}}</h2>
      </div>
      <div class="column has-text-right box-close" data-testid="box-close" (click)="closeDialog()">
        <img src="../../../assets/icon/ic_close_gray.png" width="24">
        <!-- <div class="icon-position pb-0 icon-close" ></div> -->
      </div>
    </div>
    <!-- <div style="padding-bottom: 18px;"> -->

    <mat-dialog-content>
      <coupon-card-horizontal-dialog [couponTemplate]="couponTemplate" [coupons]="coupons" [amount]="amount"
        [couponChannel]='couponChannel'
        (onActionNavigateToCouponDetail)="navigateToCouponDetail($event)"
        (onActionTrackGAUseCoupon)="onActionTrackGAUseCoupon()"
        (onActionUseCoupon)="navigateToUseCoupon($event)"
        (onActionUseCouponPaymentTopup)="navigateToInternal($event)"
        (onActionUseCouponExternalURL)="navigateToExternal($event)"
        (onActionSetStepRoute)="setStepRouteFlag($event)"
        (onActionSetToCloseDialog)="setToCloseDialog($event)">
      </coupon-card-horizontal-dialog>
    </mat-dialog-content>
    <!-- </div> -->

  </section>
</div>