import { createReducer, on } from '@ngrx/store';
import * as RootStoreType from '../model/coupon-state.model';
import * as CouponActions from './coupon.actions';
import { cloneDeep } from 'lodash';
import { CouponStatus } from '../model/coupon-enum.model';

const initialState: RootStoreType.CouponState = {
    isInitialised: false,
    isLoading: true,
    accressToken: '',
    customerTutorial: {
        data: {
            hasDoneTutorial: undefined
        },
        result: {}
    },
    coupons: {
        data: {
            collections: []
        },
        result: {}
    },
    couponDetail: {
        data: {
            coupon: null
        },
        result: {}
    },
    myCoupon: {
        data: {
            activeCoupons: [],
            expiredCoupons: [],
            usedCoupons: []
        },
        result: {}
    },
    couponList: {
        data: {
            eligibleCoupons: [],
            paymentDeeplinkUrl: '',
        },
        result: {}
    },
    collectCoupon: {
        data: {
            couponOrder: undefined,
            numberOfCollectedCoupons: undefined
        },
        result: undefined
    },
    redeemCoupon: {
        data: {
            couponCode: ''
        },
        result: {}
    },
    confirmRedeemCoupon: {
        result: undefined
    },
    reserveCouponOrder: {
        result: undefined
    },
    completeCoachMark: {
        result: undefined
    },
    // permissionUseCoupon: undefined,
    permissionUseCoupon: {
        permissionUseCoupon: undefined,
        result: undefined,
        status: undefined,
    },
    categories: [],
    couponSearch: {
        keyword: '',
        defaultSearchKeyword: [],
        searchResult: undefined,
        defaultSearchKeywordIndex: 0,
    }
};

export const CouponReducer = createReducer<RootStoreType.CouponState>(
    initialState,
    on(CouponActions.saveAccressToken, (state, action) => {
        return {
            ...state,
            accressToken: action.accressToken
        }
    }),
    on(CouponActions.loadCoupons, (state, action) => {
        return {
            ...state,
            isLoading: action.isLoading,
        }
    }),
    on(CouponActions.loadCouponsSuccess, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isInitialised: true,
            coupons: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadCouponDetail, (state, action) => {
        return {
            ...state,
            isLoading: action.isLoading,
        }
    }),
    on(CouponActions.loadCouponDetailSuccess, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isInitialised: true,
            couponDetail: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadMyCoupons, (state, action) => {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    }),
    on(CouponActions.loadMyCouponSuccess, (state, action) => {
        return {
            ...state,
            isLoading: false,
            isInitialised: true,
            myCoupon: {
                data: action.data.data,
                result: action.data.result
            }
        }
    }),
    on(CouponActions.loadCouponList, (state, action) => {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    }),
    on(CouponActions.loadCouponListSuccess, (state, action) => {

        return {
            ...state,
            isLoading: false,
            isInitialised: true,
            couponList: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadCollectedCouponListSuccess, (state, action) => {
        const newCouponOrder = cloneDeep(action.data);
        const newCollectCoupon = newCouponOrder.find(newCoupon => newCoupon.orders[0].status === CouponStatus.COLLECTED)!;
        // Use to push on last coupon.
        const newOutOfQuotaCoupons = newCouponOrder.find(newCoupon => newCoupon.orders[0].status !== CouponStatus.COLLECTED);
        const stateCoupon = cloneDeep(state.couponList.data.eligibleCoupons);
        const existingCoupon = stateCoupon.find((coupon) => coupon.couponId === newCollectCoupon.couponId && coupon.orders[0].status === CouponStatus.COLLECTED);
        const collectedCoupon = stateCoupon.find((coupon) => coupon.couponId === newCollectCoupon.couponId && coupon.orders[0].status === CouponStatus.NEW);

        if (existingCoupon) {
            const existingCouponOrder = existingCoupon.orders.map((order) => order.order);
            newCollectCoupon.orders = newCollectCoupon.orders.filter((order) => !existingCouponOrder.includes(order.order));
        }

        if (collectedCoupon) {
            collectedCoupon.couponId = newCollectCoupon.couponId;
            collectedCoupon.campaignCode = newCollectCoupon.campaignCode;
            collectedCoupon.couponShortTitle = newCollectCoupon.couponShortTitle;
            collectedCoupon.couponSubtitle = newCollectCoupon.couponSubtitle;
            collectedCoupon.deductionType = newCollectCoupon.deductionType;
            collectedCoupon.imageUrl = newCollectCoupon.imageUrl;
            collectedCoupon.isHiddenProgressBar = newCollectCoupon.isHiddenProgressBar;
            collectedCoupon.maximumCouponPerUser = newCollectCoupon.maximumCouponPerUser;
            collectedCoupon.maximumRepetitiveCouponPerUser = newCollectCoupon.maximumRepetitiveCouponPerUser;
            collectedCoupon.numberOfTemporaryOutOfQuotaCoupon = newCollectCoupon.numberOfTemporaryOutOfQuotaCoupon;
            collectedCoupon.numberOfOutOfQuotaCoupon = newCollectCoupon.numberOfOutOfQuotaCoupon;
            collectedCoupon.name = newCollectCoupon.name;
            collectedCoupon.numberOfCollectedCoupons = newCollectCoupon.orders.length;
            collectedCoupon.progressionPercent = newCollectCoupon.progressionPercent;
            collectedCoupon.repetitiveStatus = newCollectCoupon.repetitiveStatus;
            collectedCoupon.isRepetitiveResetQuota = newCollectCoupon.isRepetitiveResetQuota;
            collectedCoupon.repetitiveTime = newCollectCoupon.repetitiveTime;
            collectedCoupon.orders = newCollectCoupon.orders;
        }

        if (newOutOfQuotaCoupons) {
            stateCoupon.push(newOutOfQuotaCoupons);
        }

        return {
            ...state,
            couponList: {
                ...state.couponList,
                data: {
                    ...state.couponList.data,
                    eligibleCoupons: stateCoupon
                },
            }
        };
    }),
    on(CouponActions.loadCollectCouponSuccess, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadCollectCouponFailure, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: {},
                result: action.error.error.result
            }
        }
    }),
    on(CouponActions.clearCollectCoupon, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: {
                    couponOrder: undefined,
                    numberOfCollectedCoupons: undefined
                },
                result: undefined
            }
        };
    }),
    on(CouponActions.clearRedeemCoupon, (state, action) => {
        return {
            ...state,
            redeemCoupon: {
                data: {
                    couponCode: ''
                },
                result: undefined
            }
        };
    }),
    on(CouponActions.loadRedeemCouponSuccess, (state, action) => {
        return {
            ...state,
            redeemCoupon: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadRedeemCouponFailure, (state, action) => {
        return {
            ...state,
            redeemCoupon: {
                data: { couponCode: "" },
                result: action.error.error.result
            }
        };
    }),
    on(CouponActions.clearCouponDetail, (state, action) => {
        return {
            ...state,
            couponDetail: {
                data: {
                    coupon: null
                },
                result: {}
            }
        };
    }),
    on(CouponActions.loadReserveCouponOrderSuccess, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadReserveCouponOrderFailure, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: action.error.error.result
            }
        }
    }),
    on(CouponActions.clearReserveCouponOrder, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: undefined
            }
        };
    }),
    on(CouponActions.loadGetCustomerTutorialCoachMarkSuccess, (state, action) => {
        return {
            ...state,
            customerTutorial: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CouponActions.loadUpdateCustomerTutorialCoachMarkSuccess, (state, action) => {
        return {
            ...state,
            customerTutorial: {
                ...state.customerTutorial,
                data: {
                    hasDoneTutorial: true
                }
            }
        };
    }),
    on(CouponActions.loadPermissionUseCouponSuccess, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: action.data.permissionUseCoupon,
                result: action.data.result,
                status: action.data.status,
            }
        };
    }),
    on(CouponActions.loadPermissionUseCouponFailure, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: false,
                result: action.error.error.result,
                status: undefined,
            }
        }
    }),
    on(CouponActions.clearPermissionUseCoupon, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: undefined,
                result: undefined,
                status: undefined
            }
        };
    }),
    on(CouponActions.loadCouponsCategory, (state, action) => {
        return {
            ...state,
            // isLoading: action.isLoading,
        }
    }),
    on(CouponActions.loadCouponsCategorySuccess, (state, action) => {
        return {
            ...state,
            categories: action.data
        };
    }),
    on(CouponActions.loadSeachCoupon, (state, action) => {
        return {
            ...state,
            isLoading: true,
            couponSearch: {
                ...state.couponSearch,
                keyword: action.keyword,
            }
        }
    }),
    on(CouponActions.loadSeachCouponSuccess, (state, action) => {
        return {
            ...state,
            isLoading: false,
            couponSearch: {
                ...state.couponSearch,
                searchResult: action.data
            }
        };
    }),
    on(CouponActions.saveSeachKeyword, (state, action) => {
        return {
            ...state,
            couponSearch: {
                ...state.couponSearch,
                keyword: action.keyword,
            }
        }
    }),
    on(CouponActions.saveDefaultSeachKeyword, (state, action) => {
        return {
            ...state,
            couponSearch: {
                ...state.couponSearch,
                defaultSearchKeyword: action.defaultSearchKeyword,
            }
        }
    }),
    on(CouponActions.saveDefaultSeachKeywordIndex, (state, action) => {
        return {
            ...state,
            couponSearch: {
                ...state.couponSearch,
                defaultSearchKeywordIndex: action.defaultSearchKeywordIndex,
            }
        }
    }),
    on(CouponActions.clearSeachCoupon, (state, action) => {
        return {
            ...state,
            couponSearch: {
                ...state.couponSearch,
                keyword: '',
                searchResult: undefined
            }
        };
    }),
    on(CouponActions.clearSearchCouponResult, (state, action) => {
        return {
            ...state,
            couponSearch: {
                ...state.couponSearch,
                searchResult: undefined
            }
        };
    }),
);
