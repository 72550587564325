import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map, Subject, take, takeUntil } from 'rxjs';
import { UtilService } from 'src/app/service/util.service';
import { CouponService } from 'src/shared/coupon.service';
import { ModalTemplateComponent } from 'src/app/components/modal-template/modal-template.component';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { ICouponListModel, IPermissionUseCouponResponse } from 'src/app/model/coupon-model.model';
import { SelectedCoupon } from 'src/app/root-store/root-store.model';
import { ModalService } from 'src/app/service/modal.service';
import { PageRoute } from 'src/app/configs/route-config';
import { IModalTemplate } from 'src/app/model/coupon-modal.model';
import { modalWording, ResponseCode, ResponseMessage } from 'src/app/configs/app-config';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';
import { BottomContentSliderDialogComponent } from 'src/app/components/bottom-content-slider-dialog/bottom-content-slider-dialog.component';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { getCouponRepetitiveTime, getExpiredTime, getPeriodTime } from 'src/app/helpers/period-time.helper';
import { CountdownEvent } from 'ngx-countdown';
import { CouponEventTagAction } from 'src/app/model/coupon-ga.model';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponStatus, InternalNavigationType, PeriodStatus, RegisterStatus } from 'src/app/model/coupon-enum.model';
import { checkRegisterStatus, handleErrorCouponStatus } from 'src/app/helpers/coupon.helper';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel, interfaceToNativeCommand, interfaceToNativeContentDestination } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { FirebaseParam } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { CouponCardHorizontalComponent } from 'src/app/components/coupon-card-horizontal/coupon-card-horizontal.component';
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-couponlist',
  templateUrl: './couponlist.component.html',
  styleUrls: ['./couponlist.component.scss']
})
export class CouponlistComponent implements OnInit {
  couponTemplate = CouponCardTemplate.COUPON_LIST
  @ViewChild('toastPopup') toastPopup: any;
  @ViewChild("couponCard") couponCards: CouponCardHorizontalComponent;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  couponList: ICouponListModel[] = [];
  registerCallbackUrl = this.rootStoreService.jwtSession?.registerCallbackUrl ?? "mycoupon";
  processingCallbackUrl = this.rootStoreService.jwtSession!.processingCallbackUrl;
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  chooseCouponCallbackUrl = this.rootStoreService.jwtSession!.chooseCouponCallbackUrl;
  couponChannel: string = this.rootStoreService.jwtSession!.couponChannel;
  withHeader: boolean = true;
  amount: string = '';
  CouponStatus = CouponStatus;
  isLoading$ = this.couponService.isLoading$;
  selectedCoupon$ = this.couponService.selectedCoupon$;
  isLoadingCollectCoupon$ = this.couponService.isLoadingCollectCoupon$;
  countTimeCoupon = 0;
  isOpenModal: boolean = false;
  isEmptyCoupon: boolean = false;
  private _today: Date;
  private _countRepetitiveTime = 0;
  private _countTimePeriod = 0;
  private _countTimeAllowed = 0;
  private _couponIdOverlay = '';
  private _couponIsStamp = 0;
  private _destroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private couponService: CouponService,
    private rootStoreService: RootStoreService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
    private sanitizer: DomSanitizer,

  ) {
    this.utilService.startLoading(PageRoute.COUPON_LIST);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['amount']) localStorage.setItem('amount', params['amount']);
      this.amount = params['amount'] ?? localStorage.getItem('amount');
    });

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.registerCallbackUrl = jwtSession.registerCallbackUrl;
      this.chooseCouponCallbackUrl = jwtSession.chooseCouponCallbackUrl;
      this.processingCallbackUrl = jwtSession.processingCallbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.withHeader = jwtSession.withHeader ?? true;
    })

    this.couponService.saveStepRouteConfig([PageRoute.PATH_COUPON_LIST]);
    this.couponService.savePreviousUrl(PageRoute.PATH_COUPON_LIST);
    this.couponService.saveCurrentPage(PageRoute.PATH_COUPON_LIST);

    this.couponService.isInitialised$.pipe(first((v) => v)).subscribe((v) => {
      this.couponService.loadFetchCouponList(this.amount, true);
      this.changeDetectorRef.detectChanges();
    });

    this.couponService.couponList$
      .pipe(filter((v) => v.result.code === "0000"), takeUntil(this._destroy))
      .subscribe((response) => {
        this.isEmptyCoupon = response.data.eligibleCoupons.length === 0;
        this.couponList = cloneDeep(response.data.eligibleCoupons) ?? [];
        this._today = new Date();

        // Repetitive Time
        this._countRepetitiveTime = this.getCouponRepetitiveTime();

        // Period Time
        this._countTimePeriod = this.getCouponPeriodTimeCountNearCurrentTime();
        this._countTimeAllowed = this.getCouponAllowedTimeCountNearCurrentTime();
        this._couponIsStamp = this.getCouponIsStampNearCurrentTime();

        // Period Time
        if (this._countTimePeriod > 0 && this._countTimeAllowed > 0)
          this.countTimeCoupon = this._countTimeAllowed > this._countTimePeriod ? this._countTimePeriod : this._countTimeAllowed;
        else
          this.countTimeCoupon = this._countTimeAllowed === 0 ? this._countTimePeriod : this._countTimeAllowed

        // Stamp Coupon
        if (this._couponIsStamp > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._couponIsStamp ? this._couponIsStamp : this.countTimeCoupon;
        }

        // Repetitive Time
        if (this._countRepetitiveTime > 0) {
          this.countTimeCoupon = this.countTimeCoupon > this._countRepetitiveTime ? this._countRepetitiveTime : this.countTimeCoupon;
        }

        if (!isEmpty(this._couponIdOverlay)) {
          const filterCouponOverlay = this.couponList.find(coupon => coupon.couponId === this._couponIdOverlay);
          if (filterCouponOverlay) this.modalService.couponData.next(filterCouponOverlay)
          else this.modalService.couponData.next(null);
        }
        this.changeDetectorRef.detectChanges();

        this.couponService.isLoadingCollectCoupon$.next(false);
        this.couponCards?.loadAnimationCollectButton();
        this.changeDetectorRef.detectChanges();

      });

    this.couponService.isLoadingCollectedSccuess$
      .pipe(filter((v) => Boolean(v)), takeUntil(this._destroy))
      .subscribe((response) => {
        this.toastPopup.nativeElement.classList.remove("is-hidden");
        this.toastPopup.nativeElement.classList.add("fade-out-text");

        setTimeout(() => {
          this.toastPopup.nativeElement.classList.add("is-hidden");
          this.toastPopup.nativeElement.classList.remove("fade-out-text");
        }, 3000);
      });

  }

  ngAfterViewInit() {
    this.isLoading$.pipe(filter((v) => !Boolean(v)), takeUntil(this._destroy)).subscribe((v) => {
      this.changeDetectorRef.detectChanges();
      const elCard: HTMLElement = document.getElementById('couponCardList')!;
      const countCouponCardPerScreen = (elCard?.offsetHeight ?? 0) / 130; // check count coupon per screen(card+padding top+padding bottom)
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.couponService.isLoadingCollectedSccuess$.next(false);
  }

  private getPeriodTimeCouponStartDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;
    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].couponStartDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodRedemptionStartDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].redemptionStartDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].couponEndDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getPeriodTimeCouponEndRedemptionDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getPeriodTime(this._today, coupon.orders[0].redemptionEndDateTime);
      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return Math.ceil(averageCountTime / 1000);
  }

  private getExpiredTimeCouponEndDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getExpiredTime(this._today, coupon.orders[0].couponEndDateTime);

      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  private getExpiredTimeCouponEndRedemptionDateTime(periodCoupons: ICouponListModel[]) {
    let averageCountTime = 0;

    periodCoupons.forEach((coupon: ICouponListModel) => {
      const timeResult = getExpiredTime(this._today, coupon.orders[0].redemptionEndDateTime);
      if (timeResult === 0)
        return;

      averageCountTime = averageCountTime === 0 ? timeResult : (timeResult > averageCountTime ? averageCountTime : timeResult);
    });

    return averageCountTime;
  }

  getCouponPeriodTimeCountNearCurrentTime() {
    const newCouponsPeriod = this.couponList.filter((coupon: ICouponListModel) => coupon.orders[0].periodStatus === PeriodStatus.PERIOD && coupon.orders[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponStartDateTime(newCouponsPeriod)

    const collectedCouponsPeriod = this.couponList.filter((coupon: ICouponListModel) => coupon.orders[0].periodStatus === PeriodStatus.PERIOD && coupon.orders[0].status === CouponStatus.COLLECTED);
    const averageCollected = this.getPeriodRedemptionStartDateTime(collectedCouponsPeriod)

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponAllowedTimeCountNearCurrentTime() {
    const newCouponsAllowed = this.couponList.filter((coupon: ICouponListModel) => coupon.orders[0].periodStatus === PeriodStatus.ALLOWED && coupon.orders[0].status === CouponStatus.NEW);
    const averageNew = this.getPeriodTimeCouponEndDateTime(newCouponsAllowed)

    const collectedCouponsAllowed = this.couponList.filter((coupon: ICouponListModel) => coupon.orders[0].periodStatus === PeriodStatus.ALLOWED && coupon.orders[0].status === CouponStatus.COLLECTED);
    const averageCollected = this.getPeriodTimeCouponEndRedemptionDateTime(collectedCouponsAllowed)

    if (averageNew > 0 && averageCollected > 0)
      return (averageNew > averageCollected ? averageCollected : averageNew);

    return (averageNew === 0 ? averageCollected : averageNew);

  }

  getCouponIsStampNearCurrentTime() {
    const couponEndDateIsStamp = this.couponList.filter((coupon: ICouponListModel) => this.isIncludeCouponStatusRealTimeCouponEndDate(coupon.orders[0].status) || (coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.orders[0].order === 0));
    const averageCouponCollectIsStamp = this.getExpiredTimeCouponEndDateTime(couponEndDateIsStamp);

    const couponRedemptionIsStamp = this.couponList.filter((coupon: ICouponListModel) => (coupon.orders[0].status === CouponStatus.OUT_OF_USED_QUOTA && coupon.orders[0].order > 0));
    const averageCouponRedemptionIsStamp = this.getExpiredTimeCouponEndRedemptionDateTime(couponRedemptionIsStamp);

    if (averageCouponCollectIsStamp > 0 && averageCouponRedemptionIsStamp > 0)
      return (averageCouponCollectIsStamp > averageCouponRedemptionIsStamp ? averageCouponRedemptionIsStamp : averageCouponCollectIsStamp);

    return (averageCouponCollectIsStamp === 0 ? averageCouponRedemptionIsStamp : averageCouponCollectIsStamp);
  }

  getCouponRepetitiveTime() {
    const couponRepetitive = this.couponList.filter((coupon: ICouponListModel) => coupon.repetitiveStatus === true && coupon.isRepetitiveResetQuota);
    const averageNew = getCouponRepetitiveTime(couponRepetitive);

    return averageNew
  }

  usePaymentTopup(coupon: any, compCode: string | null, destination: string, index: number = 0, mode?: string) {
    this.setGATag('Use', coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Use_${destination}`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode!
        }
      ]
    );
    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.orders[index].order, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          this.displayPermissionError(coupon.campaignCode, response, true);
          return;
        }
        this.couponService.loadReserveCouponOrder(coupon.couponId, coupon.orders[index].order, mode);
        this.couponService.clearPermissionUseCoupon();
      });
    this.couponService.reserveCouponOrder$.pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', coupon.couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', coupon.couponId) || "";
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode,
              }
            ]
          };

          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.setStepRouteFlag()
            this.couponService.clearReserveCouponOrder();
          });
          return;
        }

        if (response.result.code == ResponseCode.ERROR_NOT_ELIGIBLE) {
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode
              }
            ],
          };

          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.changeDetectorRef.detectChanges();
            this.couponService.clearReserveCouponOrder();
            this.couponService.loadFetchCouponList(this.amount, false);
          });
          return;
        }

        if (response.result.code == ResponseCode.SUCCESS) {
          this.couponService.clearReserveCouponOrder();
          //check destination
          if (destination === InternalNavigationType.PT_PAYMENT || destination === InternalNavigationType.PT_TOPUP) {
            this.webviewToNativeInterfaceService.interfaceToNative(
              {
                command: interfaceToNativeCommand.COMMAND_ROUTING,
                content: {
                  destination: destination,
                  payeeCode: compCode,
                  campaignId: coupon.couponId,
                  channel: "PT",
                }
              }
            );
          }
        } else {
          const modalObj: IModalTemplate = {
            title: modalWording.outOfService,
            detail: modalWording.pleaseTryAgainLater,
            analyticModal: {
              eventCategory: AnalyticCategory.COUPON_API_ERROR,
              firebaseParam: [
                {
                  key: AnalyticFirebaseParam.CAMPAIGN_ID,
                  value: coupon.campaignCode!
                }
              ]
            },
            button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
            deeplinkCallbackUrl: undefined
          };

          this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
            this.couponService.clearReserveCouponOrder();
          });
        }
      });
  }

  navigateExternalURL(coupon: any, externalUrl: string = "", index: number = 0) {
    this.couponService.loadPermissionUseCoupon(coupon.couponId, coupon.couponInfo[index].receivedOrder, true);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {
        if (!response?.permissionUseCoupon) {
          this.displayPermissionError(coupon.campaignCode, response, true);
          return;
        }

        this.setGATag('Use', coupon.campaignCode);
        this.webviewToNativeInterfaceService.trackAction(
          {
            action: AnalyticAction.CLICK,
            category: AnalyticCategory.COUPON_COPAY_CHOOSING,
            label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Use_ExternalURL`,
            firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
          },
          [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: coupon.campaignCode!
            }
          ]
        );
        const modalObj: IModalTemplate = {
          title: modalWording.confirmNavigateExternalUrl,
          detail: modalWording.exitFromAppGoToParner,
          analyticModal: {
            eventCategory: AnalyticCategory.COUPON_NAVIGATE_EXTERNAL_URL,
            firebaseParam: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: coupon.campaignCode!
              }
            ]
          },
          button: [
            { name: modalWording.cancel, action: "", eventLabel: AnalyticLabel.CANCEL },
            { name: modalWording.confirm, action: "confirm", eventLabel: AnalyticLabel.CONFIRM }
          ],
        }
        this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
          if (result == "confirm") {
            window.location.href = this.utilService.safeURL(externalUrl);
          }
        });
        this.couponService.clearPermissionUseCoupon();
      });
  }

  navigateToCouponDetail(coupon: ICouponListModel, isSetGA: boolean = false, index: number = 0) {
    if (isSetGA) {
      this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_Detail`, coupon.campaignCode);

      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.CLICK,
          category: AnalyticCategory.COUPON_COPAY_CHOOSING,
          label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Detail`,
          firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
        },
        [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: coupon.campaignCode,
          }
        ]
      );
    }

    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      order: coupon.orders[index].order,
      couponStatus: coupon.orders[index].status,
      formPage: 'C'
    }
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_LIST);
    this.router.navigate([PageRoute.PATH_COUPON_DETAIL], { queryParams: { couponId: coupon.couponId, order: coupon.orders[index].order, previousurl: PageRoute.PATH_COUPON_LIST }, replaceUrl: true });
  }

  pickCoupon(element: ElementRef<any>, couponId: string, couponOrder: number, campaignCode: string = '') {
    this.couponCards.collectedCoupon.push(couponId);
    this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_Collect`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Collect`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: campaignCode,
        }
      ]
    );

    this.couponService.loadPermissionUseCoupon(couponId, couponOrder);
    this.couponService.permissionUseCoupon$.pipe(filter((v) => !isNil(v.permissionUseCoupon)), take(1))
      .subscribe((response) => {

        if (!response.permissionUseCoupon) {
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.displayPermissionError(campaignCode, response);
          return;
        }

        this.couponService.loadFetchCollectCoupon(couponId, couponOrder);
        this.couponService.clearPermissionUseCoupon();
      });

    this.couponService.collectCoupon$
      .pipe(filter((v) => Boolean(v.result)), take(1))
      .subscribe((response) => {
        this.couponService.clearCollectCoupon();
        this.couponCards.collectedCoupon = this.couponCards.collectedCoupon.filter((v) => v !== couponId);

        if (response.result.code == ResponseCode.ERROR_NOT_REGISTERED || response.result.code == ResponseCode.ERROR_REGISTERING) {
          const unRegisteredCallbackUrl = this.registerCallbackUrl?.replace('${campaignId}', couponId) + '&callbackMode=H' || "";
          const pendingRegisteredCallbackUrl = this.processingCallbackUrl?.replace('${campaignId}', couponId) || "";

          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_NOT_REGISTERED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_REGISTERING]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ]
          };
          this.couponService.isLoadingCollectCoupon$.next(false);
          this.couponCards?.loadAnimationCollectButton();
          const modalObj = checkRegisterStatus(response.result.code, unRegisteredCallbackUrl, pendingRegisteredCallbackUrl, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.couponCards.loadAnimationCollectButton(false);
              this.isOpenModal = false;
              this.setStepRouteFlag();
            });
          }
          return;
        }

        if (response.result.code == ResponseCode.ERROR_OUT_OF_QUOTA || response.result.code == ResponseCode.ERROR_COUPON_EXPIRED || response.result.code == ResponseCode.ERROR_COUPON_NOTFOUND) { // quota = 0 || expired || Notfound
          const firebaseParams: { [key: string]: FirebaseParam[] } = {
            [ResponseCode.ERROR_OUT_OF_QUOTA]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_COUPON_EXPIRED]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_COUPON_NOTFOUND]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
            [ResponseCode.ERROR_NOT_ELIGIBLE]: [
              {
                key: AnalyticFirebaseParam.CAMPAIGN_ID,
                value: campaignCode
              }
            ],
          };

          this.couponService.isLoadingCollectCoupon$.next(false);
          const modalObj = handleErrorCouponStatus(response.result.code, firebaseParams);
          if (!this.isOpenModal) {
            this.isOpenModal = true;
            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
              this.isOpenModal = false;
              this.couponService.loadFetchCouponList(this.amount, false);
            });
          }

        } else if (response.result.code == ResponseCode.SUCCESS) { // success

          this.countTimeCoupon = 0;
          this.changeDetectorRef.detectChanges();
          this.couponService.loadFetchCouponList(this.amount, false, couponId);
        }
      });
  }

  setStepRouteFlag() {
    this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_LIST);
    this.utilService.removeTokenSession();
    if (localStorage.getItem("navigateExternalPageTemp")) {
      localStorage.setItem("navigateExternalPage", localStorage.getItem("navigateExternalPageTemp")!);
    }
  }

  setToLoadFech() {
    this.changeDetectorRef.detectChanges();
    this.couponService.loadFetchCouponList(this.amount, false);
  }

  openOverlay(coupon: ICouponListModel) {
    this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_MultipleCoupons`, coupon.campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_MultipleCoupons`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: coupon.campaignCode,
        }
      ]
    );

    this._couponIdOverlay = coupon.couponId;
    const titleDialog = this.modalService.getHeaderDialogButtomContnet(coupon.orders[0].periodStatus, coupon.orders[0].status as CouponStatus, true, coupon.orders[0].isAboveMinimumSpend);
    const couponData = {
      coupon: coupon,
      couponChannel: this.couponChannel,
      titleDialog: titleDialog,
      amount: this.amount,
      isInChannel: true,
      template: CouponCardTemplate.COUPON_LIST_BOTTOM_DIALOG,
      categoryEvent: AnalyticCategory.COUPON_COPAY_CHOOSING_MULTIPLE
    };

    this.modalService.openDialogButtomContnet(BottomContentSliderDialogComponent, { data: couponData, position: { bottom: '0px' } }).pipe(take(1)).subscribe((response) => {
      this._couponIdOverlay = '';
      if (response) {
        switch (response.path) {
          case PageRoute.COUPON_DETAIL: {
            this.navigateToCouponDetail(response.coupon);
            break;
          }
          case PageRoute.SCAN_COPAY: {
            this.setStepRouteFlag();
            break;
          }
          case PageRoute.INTERNAL_URL: {
            this.usePaymentTopup(response.coupon, response.compCode, response.destination);
            break;
          }
          case PageRoute.EXTERNAL_URL: {
            this.navigateExternalURL(response.coupon, response.externalUrl);
            break;
          }
          case PageRoute.COUPON_LIST: {
            this.setToLoadFech();
            break;
          }
        }
      }
    });
  }

  handleEvent(e: CountdownEvent) {
    // Timeout
    if (e.action === 'done') {
      this.countTimeCoupon = 0;
      this.changeDetectorRef.detectChanges();
      this.couponService.loadFetchCouponList(this.amount, false);
    }
  }

  private mappingSelectedCoupon(coupon: ICouponListModel, index = 0) {
    const selectedCoupon: SelectedCoupon = {
      couponId: coupon.couponId,
      couponCode: '',
      hasCountdownTimer: false,
      countdownTimeInSeconds: 0,
      order: coupon.orders[index].order,
      couponStatus: coupon.orders[index].status
    };

    return selectedCoupon;

  }

  onActionSelectedCoupon(ev: ICouponListModel) {
    const selectedCoupon = this.mappingSelectedCoupon(ev);
    this.couponService.saveSelectedCouponValue(selectedCoupon, PageRoute.PATH_COUPON_LIST);
  }

  displayPermissionError(campaignCode: string, permisionCoupon: IPermissionUseCouponResponse, isNavigate: boolean = false) {
    if (isNavigate && permisionCoupon?.status && (permisionCoupon?.status === CouponStatus.OUT_OF_QUOTA || permisionCoupon?.status === CouponStatus.OUT_OF_USED_QUOTA || permisionCoupon?.status === CouponStatus.TEMPO_OUT_OF_QUOTA)) {
      const firebaseParams: { [key: string]: FirebaseParam[] } = {
        [ResponseCode.ERROR_OUT_OF_QUOTA]: [
          {
            key: AnalyticFirebaseParam.CAMPAIGN_ID,
            value: campaignCode,
          }
        ],
      };
      const modalObj = handleErrorCouponStatus(ResponseCode.ERROR_OUT_OF_QUOTA, firebaseParams);
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
        this.changeDetectorRef.detectChanges();
        this.couponService.clearPermissionUseCoupon();
        this.couponService.loadFetchCouponList(this.amount, false);
      });
    } else {
      const modalObj: IModalTemplate = {
        title: permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_ELIGIBLE_NOTFOUND ? modalWording.conditionsUsingCouponNotMet : modalWording.outOfService,
        detail: permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_ELIGIBLE_NOTFOUND ? "" : modalWording.pleaseTryAgainLater,
        analyticModal: {
          eventCategory: permisionCoupon.result?.code == ResponseCode.ERROR_COUPON_ELIGIBLE_NOTFOUND ? AnalyticCategory.COUPON_NOTELIGIBLE : AnalyticCategory.COUPON_API_ERROR,
          firebaseParam: [
            {
              key: AnalyticFirebaseParam.CAMPAIGN_ID,
              value: campaignCode,
            }
          ]
        },
        button: [{ name: modalWording.ok, action: "confirm", eventLabel: AnalyticLabel.OK }]
      };
      this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe((result) => {
        if (result == "confirm") {
          this.changeDetectorRef.detectChanges();
          this.couponService.clearPermissionUseCoupon();
          this.couponService.loadFetchCouponList(this.amount, false);
        }
      });
    }
  }

  onActionUseCouponPaymentTopup(eventLabel: string, campaignCode: string, destination: string) {
    this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_${eventLabel}`, campaignCode);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_${eventLabel}_${destination}`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: campaignCode!
        }
      ]
    );
  }

  setUseCopayGATag(label: string, campaignCode: string = '') {
    this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_${label}`, campaignCode);

    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Use`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      },
      [
        {
          key: AnalyticFirebaseParam.CAMPAIGN_ID,
          value: campaignCode,
        }
      ]
    );
  }

  setBackGATag() {
    this.setGATag(`${AnalyticCategory.COUPON_COPAY_CHOOSING}_Back`);
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: AnalyticCategory.COUPON_COPAY_CHOOSING,
        label: `${AnalyticCategory.COUPON_COPAY_CHOOSING}_Back`,
        firebase_screen: AnalyticCategory.COUPON_COPAY_CHOOSING,
      }
    );
  }

  setGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: AnalyticCategory.COUPON_COPAY_CHOOSING,
      eventCategory: AnalyticCategory.COUPON_COPAY_CHOOSING,
      eventLabel: label,
      eventAction: AnalyticAction.CLICK,
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? '',
    }
    this.couponGAService.trackActionGATag(gaObj)
  }

  private isIncludeCouponStatusRealTimeCouponEndDate(couponStatus: string) {
    const couponStatusRealTime = [CouponStatus.OUT_OF_QUOTA, CouponStatus.TEMPO_OUT_OF_QUOTA]
    return couponStatusRealTime.some((realTimeStatus) => realTimeStatus === couponStatus);
  }

}
