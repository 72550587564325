/**
 * Feature Key State
 */

import { SafeUrl } from '@angular/platform-browser';
import { CouponState } from '../model/coupon-state.model';

export const COUPON_STATE = 'counpon';

export interface AppState {
  session: Session;
  [COUPON_STATE]: CouponState;
}

export interface Session {
  isInitialised: boolean;
  webToken: string;
  jwtSession: JWTSession;
  previousUrl: string;
  currentPage: string;
  selectedCoupon: SelectedCoupon;
  stepRouteConfig: string[];
  selectedCategoryId?: string;
}

export interface JWTSession {
  couponChannel: string;
  callbackUrl: string;
  registerCallbackUrl: string;
  processingCallbackUrl: string;
  paymentCallbackUrl: string;
  chooseCouponCallbackUrl: string;
  withHeader: undefined | boolean;
  status: string;
  mode: string;
  webTokenId: string;
  sourceOfFund: string;
  callbackDestination: string;
  entryPoint: string;
  paymentMethod: string;
}

export interface SelectedCoupon {
  couponId: string;
  couponCode?: string;
  hasCountdownTimer?: boolean;
  countdownTimeInSeconds?: number;
  order: number;
  couponStatus?: string;
  formPage?: string;
}

export interface ICouponAccressToken {
  accessToken: string;
}
