import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { Store } from '@ngrx/store';
import { RootStoreService } from 'src/app/root-store/root-store.service';
import { CouponService } from 'src/shared/coupon.service';
import { isNil } from 'lodash';
import { CouponGAService } from 'src/app/service/coupon-ga.service';
import { CouponEventTagPageView } from 'src/app/model/coupon-ga.model';
import { FirebaseScreen } from 'src/app/webview-to-native-interface/webview-to-native-interface.model';
import { WebviewToNativeInterfaceService } from 'src/app/webview-to-native-interface/webview-to-native-interface.service';
import { AnalyticCategory } from 'src/app/webview-to-native-interface/webview-to-native-interface-enum.model';
import { Subject, takeUntil } from 'rxjs';

declare let window: any;
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  couponChannel = this.rootStoreService.jwtSession?.couponChannel;
  withHeader: boolean = true;
  callbackUrl = this.rootStoreService.jwtSession!.callbackUrl;
  isAndroid: boolean = this.utilService?.isMobileDevice();
  private _destroy = new Subject<void>();

  constructor(
    private utilService: UtilService,
    private rootStoreService: RootStoreService,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService
  ) { }

  ngOnInit(): void {

    this.rootStoreService.jwtSession$.pipe(takeUntil(this._destroy)).subscribe((jwtSession) => {
      this.callbackUrl = jwtSession.callbackUrl;
      this.couponChannel = jwtSession.couponChannel;
      this.withHeader = jwtSession.withHeader ?? true;
    })

    this.utilService.stopLoading();


    const gaPageObj: CouponEventTagPageView = {
      pageTitle: AnalyticCategory.COUPON_UN_AUTHORIZED,
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackPageViewGATag(gaPageObj);

    const eventPageView: FirebaseScreen = {
      firebase_screen: AnalyticCategory.COUPON_UN_AUTHORIZED,
    }
    this.webviewToNativeInterfaceService.trackPageView(eventPageView);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
