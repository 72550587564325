import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Lodash from "lodash";
import {
    catchError,
    map,
    switchMap,
    tap,
    withLatestFrom,
    take,
    shareReplay,
} from 'rxjs/operators';

import { EMPTY, Observable, of } from 'rxjs';

import * as CouponActions from './coupon.actions';
import * as CouponSelectors from './coupon.selectors'

import * as RootSelectors from '../root-store/root-selectors';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState, SelectedCoupon } from '../root-store/root-store.model';
import { CouponService } from 'src/shared/coupon.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ICouponResponse, IFetchCouponData, IFetchCouponDetail, IFetchMyCouponData, IFetchCouponList, IFetchRedeemCoupon, IFetchCollectCoupon, IFetchReseverCouponOrder, IFetchCompleteCoachMark, ICustomerTutorial, ICouponListModel, ICouponModel } from '../model/coupon-model.model';
import { UtilService } from '../service/util.service';
import { RootStoreEffects } from '../root-store/root-effects';
import { ModalTemplateComponent } from '../components/modal-template/modal-template.component';
import { ModalService } from '../service/modal.service';
import { RootStoreService } from '../root-store/root-store.service';
import { IModalTemplate } from '../model/coupon-modal.model';
import { modalWording, ResponseCode, ResponseMessage } from '../configs/app-config';
import { PageRoute } from '../configs/route-config';
import { CouponChannel, CouponStatus, PeriodStatus } from '../model/coupon-enum.model';
import { AnalyticCategory, AnalyticLabel } from '../webview-to-native-interface/webview-to-native-interface-enum.model';
import { CouponCardTemplate } from '../configs/coupon-card-config';

@Injectable()
export class CouponEffect {

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private couponService: CouponService,
        private utilService: UtilService,
        private rootStoreEffect: RootStoreEffects,
        private router: Router,
        private modalService: ModalService,
        private rootStoreService: RootStoreService
    ) { }

    loadCouponsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadCoupons),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(CouponSelectors.selectCustomerTutorial),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, webToken, accessToken, customerTutorial, jwtSession]) => {
                const codeToken: string = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.fetchCoupons(codeToken, accessToken, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    tap((response: ICouponResponse<IFetchCouponData>) => {

                        this.rootStoreEffect.triggerDestroyRootEffect();
                        this.removeWebTokenUrl();
                        if (response?.data?.accessToken) {
                            sessionStorage.setItem('accessToken', response.data.accessToken);
                            this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))
                        }
                    }),
                    map((response: ICouponResponse<IFetchCouponData>) => {
                        if (!Boolean(customerTutorial.data.hasDoneTutorial)) {
                            this.couponService.loadGetCustomerTutorialCoachMark();
                        }

                        if (action.isLoadingCollectedSuccess) {
                            this.couponService.isLoadingCollectedSccuess$.next(true);
                        }

                        this.store.dispatch(CouponActions.loadCouponsCategorySuccess({ data: response.data.collections[0]?.categories ?? [] }));
                        this.store.dispatch(CouponActions.saveDefaultSeachKeyword({ defaultSearchKeyword: response.data.collections[0]?.defaultSearchWord ?? [] }));

                        this.utilService.stopLoading();
                        return CouponActions.loadCouponsSuccess({
                            data: response,
                        });
                    }), shareReplay(1)
                );
            })
        )
    });

    loadCouponDetailEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadCouponDetail),
            withLatestFrom(
                this.store.select(RootSelectors.selectCouponId),
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectStepRouteConfig),
                this.store.select(RootSelectors.selectedCoupon),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, couponId, webToken, accessToken, stepRouteConfig, selectedCoupon, jwtSession]) => {
                const codeToken = !Lodash.isEmpty(accessToken) ? '' : webToken;
                const amount = (stepRouteConfig[stepRouteConfig.length - 2] === PageRoute.PATH_COUPON_LIST) ? localStorage.getItem('amount') ?? undefined : undefined;
                return this.couponService.fetchCouponDetail(codeToken, accessToken, selectedCoupon.couponId, selectedCoupon.order, jwtSession.webTokenId, jwtSession.mode, amount, selectedCoupon.formPage).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err, stepRouteConfig);
                        this.navigateExternalDeeplinkForCouponDetailPage(err, stepRouteConfig);
                        return EMPTY;
                    }),
                    tap((response: ICouponResponse<IFetchCouponDetail>) => {
                        this.rootStoreEffect.triggerDestroyRootEffect();
                        this.removeWebTokenUrl();
                        if (response?.data?.accessToken) {
                            sessionStorage.setItem('accessToken', response.data.accessToken);
                            this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))
                        }
                    }),
                    map((response: ICouponResponse<IFetchCouponDetail>) => {
                        this.utilService.stopLoading();
                        if (response?.data?.coupon) {
                            if (action.isQRCode) {
                                const selectedCoupon: SelectedCoupon = {
                                    couponId: couponId,
                                    couponCode: response.data.coupon.couponCode ?? '',
                                    hasCountdownTimer: response.data.coupon.hasCountdownTimer ?? false,
                                    countdownTimeInSeconds: response.data.coupon.countdownTimeInSeconds ?? 0,
                                    order: response.data.coupon.couponOrder
                                }

                                this.router.navigate([], {
                                    relativeTo: this.activatedRoute,
                                    queryParams: selectedCoupon,
                                    queryParamsHandling: 'merge',
                                    replaceUrl: true
                                });
                            }

                            if (action.isLoadingCollectedSccuess) {
                                this.couponService.isLoadingCollectedSccuess$.next(true);
                            }
                        }

                        return CouponActions.loadCouponDetailSuccess({
                            data: response,
                        });
                    })
                );
            })
        )
    });

    loadPermissionUseCouponEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadPermissionUseCoupon),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectStepRouteConfig),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, webToken, accessToken, stepRouteConfig, jwtSession]) => {
                const codeToken = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.fetchCouponDetail(codeToken, accessToken, action.couponId, action.couponOrder, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err, stepRouteConfig);
                        this.store.dispatch(CouponActions.loadPermissionUseCouponFailure({ error: err }));
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchCouponDetail>) => {

                        let permissionUseCoupon: boolean = false;

                        if (action.checkStatus) {
                            permissionUseCoupon = (response.data.coupon?.periodStatus === PeriodStatus.ALLOWED && response.data.coupon.status === CouponStatus.COLLECTED)
                        } else {
                            permissionUseCoupon = response.data.coupon?.periodStatus === PeriodStatus.ALLOWED ? true : false;
                        }

                        const res = {
                            permissionUseCoupon: permissionUseCoupon,
                            result: response.result,
                            status: response?.data?.coupon?.status,
                        }
                        return CouponActions.loadPermissionUseCouponSuccess({
                            data: res,
                        });
                    })
                );
            })
        )
    });

    loadMyCouponEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadMyCoupons),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([_, webToken, accessToken, jwtSession]) => {
                const codeToken = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.fetchMyCoupons(codeToken, accessToken, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    tap((response: ICouponResponse<IFetchMyCouponData>) => {
                        this.rootStoreEffect.triggerDestroyRootEffect();
                        this.removeWebTokenUrl();
                        if (response?.data?.accessToken) {
                            sessionStorage.setItem('accessToken', response.data.accessToken);
                            this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))
                        }
                    }),
                    map((response: ICouponResponse<IFetchMyCouponData>) => {
                        this.utilService.stopLoading();
                        return CouponActions.loadMyCouponSuccess({
                            data: response,
                        });
                    })
                );
            })
        )
    });

    loadCouponListEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadCouponList),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession),
            ),
            switchMap(([action, webToken, accessToken, jwtSession]) => {
                const codeToken = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.fetchCouponList(codeToken, accessToken, jwtSession.webTokenId, action.amount).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    tap((response: ICouponResponse<IFetchCouponList>) => {
                        this.rootStoreEffect.triggerDestroyRootEffect();
                        this.removeWebTokenUrl();
                        if (response?.data?.accessToken) {
                            sessionStorage.setItem('accessToken', response.data.accessToken);
                            this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))
                        }
                    }),
                    map((response: ICouponResponse<IFetchCouponList>) => {
                        this.utilService.stopLoading();
                        if (action.couponId) {
                            const collectedCoupon = response.data.eligibleCoupons.filter((coupon: ICouponListModel) => coupon.couponId === action.couponId);
                            if (collectedCoupon && collectedCoupon.length > 0) {
                                this.couponService.isLoadingCollectedSccuess$.next(true);
                                return CouponActions.loadCollectedCouponListSuccess({
                                    data: collectedCoupon
                                })
                            }
                        }

                        return CouponActions.loadCouponListSuccess({
                            data: response,
                        })
                    }),
                    shareReplay(1)
                );
            })
        )
    });

    loadCollectCouponEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadCollectCoupon),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, webToken, accessToken, jwtSession]) => {
                return this.couponService.fetchCollectCoupon(accessToken, action.couponId, jwtSession.webTokenId, action.couponOrder).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        this.store.dispatch(CouponActions.loadCollectCouponFailure({ error: err }));
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchCollectCoupon>) => {
                        return CouponActions.loadCollectCouponSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadRedeemCouponEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadRedeemCoupon),
            withLatestFrom(
                this.store.select(RootSelectors.selectedCoupon),
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([_, selectedCoupon, webToken, accessToken, jwtSession]) => {
                return this.couponService.fetchRedeemCoupon(accessToken, selectedCoupon.couponId, jwtSession.webTokenId, selectedCoupon.order).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        this.store.dispatch(CouponActions.loadRedeemCouponFailure({ error: err }));
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchRedeemCoupon>) => {
                        const arrStatus = [ResponseCode.ERROR_OUT_OF_QUOTA, ResponseCode.ERROR_COUPON_EXPIRED, ResponseCode.ERROR_COUPON_NOTFOUND, ResponseCode.ERROR_NOT_ELIGIBLE];
                        if (!arrStatus.includes(response.result.code)) { // quota = 0 || expired || Notfound
                            this.couponService.loadFetchCouponDetail(true, true);
                        } else {
                            this.utilService.stopLoading();
                        }
                        return CouponActions.loadRedeemCouponSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadConfirmRedeemCouponEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadConfirmRedeemCoupon),
            withLatestFrom(
                this.store.select(RootSelectors.selectCouponId),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, couponId, accessToken, jwtSession]) => {
                return this.couponService.fetchConfirmRedeemCoupon(accessToken, couponId, jwtSession.webTokenId, action.couponOrder).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    })
                )
            })
        )
    }, { dispatch: false })

    loadReserveCouponOrderEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadReserveCouponOrder),
            withLatestFrom(
                this.store.select(RootSelectors.selectedCoupon),
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, selectedCoupon, webToken, accessToken, jwtSession]) => {
                const codeToken = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.reseverCouponOrder(accessToken, action.couponId, action.couponOrder, jwtSession.webTokenId, action.mode).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        this.store.dispatch(CouponActions.loadReserveCouponOrderFailure({ error: err }));

                        return EMPTY;
                    }),
                    map((response: IFetchReseverCouponOrder) => {
                        return CouponActions.loadReserveCouponOrderSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadCustomerTutorialCoachMarkEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadGetCustomerTutorialCoachMark),
            withLatestFrom(
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([_, accessToken, jwtSession]) => {
                return this.couponService.getCustomerTutorialCoachMark(accessToken, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<ICustomerTutorial>) => {
                        return CouponActions.loadGetCustomerTutorialCoachMarkSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadUpdateCustomerTutorialCoachMarkEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadUpdateCustomerTutorialCoachMark),
            withLatestFrom(
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([_, accessToken, jwtSession]) => {
                return this.couponService.updateCustomerTutorialCoachMark(accessToken, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    map((response: IFetchCompleteCoachMark) => {
                        return CouponActions.loadUpdateCustomerTutorialCoachMarkSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadSearchCoupon$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CouponActions.loadSeachCoupon),
            withLatestFrom(
                this.store.select(RootSelectors.selectWebToken),
                this.store.select(CouponSelectors.selectAccressToken),
                this.store.select(RootSelectors.selectJWTSession)
            ),
            switchMap(([action, webToken, accessToken, jwtSession]) => {
                const codeToken: string = !Lodash.isEmpty(accessToken) ? '' : webToken;
                return this.couponService.fetchCoupons(codeToken, accessToken, jwtSession.webTokenId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        // this.utilService.stopLoading();
                        this.utilService.checkErrorRes(err);
                        this.navigateExternalDeeplink(err);
                        return EMPTY;
                    }),
                    tap((response: ICouponResponse<IFetchCouponData>) => {

                        this.rootStoreEffect.triggerDestroyRootEffect();
                        this.removeWebTokenUrl();
                        if (response?.data?.accessToken) {
                            sessionStorage.setItem('accessToken', response.data.accessToken);
                            this.store.dispatch(CouponActions.saveAccressToken({ accressToken: response.data.accessToken }))
                        }
                    }),
                    map((response: ICouponResponse<IFetchCouponData>) => {

                        if (action.isLoadingCollectedSuccess) {
                            this.couponService.isLoadingCollectedSccuess$.next(true);
                        }

                        const keywordSearch = action.keyword.toLocaleLowerCase();
                        const filterSearchCoupon = response.data.collections[0].coupons.filter((coupon) => {
                            return (coupon.couponShortTitle.toLocaleLowerCase().includes(keywordSearch) ||
                                coupon.couponSubtitle.toLocaleLowerCase().includes(keywordSearch) ||
                                coupon.couponName.toLocaleLowerCase().includes(keywordSearch) ||
                                coupon.tags?.some(tag => tag.toLocaleLowerCase().includes(keywordSearch)));
                        });
                        
                        this.store.dispatch(CouponActions.saveDefaultSeachKeyword({ defaultSearchKeyword: response.data.collections[0]?.defaultSearchWord ?? [] }));

                        return CouponActions.loadSeachCouponSuccess({
                            data: filterSearchCoupon,
                        });
                    }), shareReplay(1)
                );
            })
        )
    });

    private navigateExternalDeeplink(err: HttpErrorResponse, stepRouteConfig: string[] = []) {
        if (err?.error?.result?.code == ResponseCode.ERROR_API) {
            const modalObj: IModalTemplate = {
                title: modalWording.outOfService,
                detail: modalWording.pleaseTryAgainLater,
                analyticModal: {
                    eventCategory: AnalyticCategory.COUPON_API_ERROR,
                },
                button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                deeplinkCallbackUrl: this.rootStoreService.jwtSession!.callbackUrl
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe();
            return;
        }
        if (err?.error?.result?.code == ResponseCode.ERROR_COUPON_NOTFOUND) {
            const modalObj: IModalTemplate = {
                title: modalWording.notfoundCoupon,
                detail: "",
                analyticModal: {
                    eventCategory: AnalyticCategory.COUPON_NOTFOUND,
                },
                button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                deeplinkCallbackUrl: stepRouteConfig.length <= 1 ? this.rootStoreService.jwtSession?.callbackUrl : undefined
            };

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
                if (stepRouteConfig.length > 1) {
                    const path = stepRouteConfig[stepRouteConfig.length - 2]
                    this.router.navigate([path], { replaceUrl: true });
                    this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                }
            });
            return;
        }
    }

    private navigateExternalDeeplinkForCouponDetailPage(err: HttpErrorResponse, stepRouteConfig: string[] = []) {
        if (err?.error?.result?.code == ResponseCode.ERROR_COUPON_ELIGIBLE_NOTFOUND) {
            const couponChannel = this.rootStoreService.jwtSession?.couponChannel;
            const modalObj: IModalTemplate = {
                title: modalWording.notFoundYourCoupon,
                detail: "",
                analyticModal: {
                    eventCategory: AnalyticCategory.COUPON_NOTELIGIBLE,
                },
                button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                // deeplinkCallbackUrl: this.rootStoreService.jwtSession!.callbackUrl
                deeplinkCallbackUrl: stepRouteConfig.length <= 1 && couponChannel !== CouponChannel.CNX && couponChannel !== CouponChannel.NEXT ? this.rootStoreService.jwtSession?.callbackUrl : undefined
            }

            this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
                if (stepRouteConfig.length > 1) {
                    const path = stepRouteConfig[stepRouteConfig.length - 2]
                    this.router.navigate([path], { replaceUrl: true });
                    this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                } else if (couponChannel === CouponChannel.CNX || couponChannel == CouponChannel.NEXT) {
                    this.rootStoreEffect.triggerDestroyRootEffect();
                    this.removeWebTokenUrl();
                    if (err?.error?.data?.accessToken) {
                        sessionStorage.setItem('accessToken', err.error.data.accessToken);
                        this.store.dispatch(CouponActions.saveAccressToken({ accressToken: err.error.data.accessToken }))
                    }
                    this.router.navigate([PageRoute.PATH_HOME], { replaceUrl: true });
                    this.couponService.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                }
            });
            return;
        }
    }

    private removeWebTokenUrl() {
        if (this.activatedRoute?.snapshot?.queryParams['code'] ||
            this.activatedRoute?.snapshot?.queryParams['authorizationCode']
        ) {
            const params: Params = {
                code: null,
                authorizationCode: null,
                webConfig: null
            };
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
                replaceUrl: true
            });
        }
    }

}
