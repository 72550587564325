import { Injectable } from "@angular/core";
import { EventFunctionList, EventAction, FirebaseParam, FirebaseScreen, EventActionWithNative, EventFunctionToNative } from "./webview-to-native-interface.model";

declare const webviewToNativeInterface: EventFunctionList;
declare const nativeInterface: EventFunctionToNative;
declare const window: any;
@Injectable({
  providedIn: 'root',
})
export class WebviewToNativeInterfaceService {
  constructor(
  ) { }

  trackAction(event: EventAction, firebaseParam?: FirebaseParam[], useNativeInterface: boolean = false) {
    if (useNativeInterface) {
      const data = firebaseParam
        ? {
          ga: {
            event,
            firebaseParam,
          }
        }
        : {
          ga: {
            event
          }
        };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.nativeInterface.postMessage(body);
        } else {
          nativeInterface.onRetrievePayloadString(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        // console.log(body);
        // handle when open on web
      }
    } else {
      const data = firebaseParam
        ? {
          event,
          firebaseParam,
        }
        : { event };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
        } else {
          webviewToNativeInterface.gaTagging(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        // console.log(body);
        // handle when open on web
      }
    }
  }

  trackPageView(event: FirebaseScreen) {
    const data = { event }
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
      } else {
        webviewToNativeInterface.gaTagging(body);
      }
    } catch (error) {
      // uncomment for GA testing purpose
      // console.log(body);
      // handle when open on web
    }
  }

  interfaceToNative(event: EventActionWithNative) {
    const body = JSON.stringify(event);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.nativeInterface.postMessage(body)
      } else {
        nativeInterface.onRetrievePayloadString(body);
      }
    } catch (error) {
      // console.log(error);
    }
  }
}
