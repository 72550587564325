export enum PageRoute {
    // Used in routing module.
    
    HOME = 'home',
    MY_COUPON = 'mycoupon',
    COUPON_LIST = 'couponlist',
    COUPON_DETAIL = 'coupon-detail',
    QR_CODE = 'qrcode',
    MERCHANT_CONFIRM = 'merchant-confirm',
    UNDER_MAINTENANCE = 'under-maintenance',
    COMING_SOON = 'coming-soon',
    UNAUTHORIZED = 'unauthorized',
    OFFLINE = 'offline',
    NOT_FOUND = 'notfound',
    SCAN_COPAY = 'scan-copay',
    SKELETION = 'skeleton',
    SEARCH_COUPON = 'search-coupon',
    INTERNAL_URL = 'internal-url',
    EXTERNAL_URL = 'external-url',


    // Used in navigate.

    PATH_HOME = '/home',
    PATH_MY_COUPON = '/mycoupon',
    PATH_COUPON_LIST = '/couponlist',
    PATH_COUPON_DETAIL = '/coupon-detail',
    PATH_QR_CODE = '/qrcode',
    PATH_MERCHANT_CONFIRM = '/merchant-confirm',
    PATH_UNDER_MAINTENANCE = '/under-maintenance',
    PATH_COMING_SOON = '/coming-soon',
    PATH_UNAUTHORIZED = '/unauthorized',
    PATH_OFFLINE = '/offline',
    PATH_NOT_FOUND = '/notfound',
    PATH_SEARCH_COUPON = '/search-coupon'

  }
  